import React, { useState, useRef, useEffect } from 'react'

import styled, { ThemeProvider } from 'styled-components'
import { lightTheme, darkTheme, GlobalStyles } from '../themes.js'
import { AnimatePresence, motion } from 'framer-motion';
import './styles/pricing.scss'
import { Tooltip } from '@mui/material';

function Pricing(themeData) {

  useEffect(() => {
    document.title = 'Pricing • Jins Gonzales Design';
  }, []);

  // animating divs
  // static website plans
  const [isVisible1, setIsVisible1] = useState(true);
  const toggleVisibility1 = () => {
    setIsVisible1(!isVisible1);
  };
  // second toggle
  const [isVisible2, setIsVisible2] = useState(false);
  const toggleVisibility2 = () => {
    setIsVisible2(!isVisible2);
  };

  
    return (
        <ThemeProvider theme={themeData.theme === 'light' ? lightTheme : darkTheme}>
        <GlobalStyles />
        <div id='pricing'>

          <section className="pricing section-top section-bottom">
            <div className='pagetopMargin'></div>
            <div className="wrapper">
                <div className="flex flex-column">
                    <span className="title">Pricing</span>
                </div>
            </div>

            <div className='dropdown-container dropdown-wrapper'>
              <div className='wrapper'>
                <div className='each-service'>
                  <div className='title-control' onClick={toggleVisibility1}>
                    <span className='title-name'>
                      Static Website Plans
                    </span>
                    <span className='contol-icon-holder'>
                      <motion.span class="material-symbols-outlined contol-icon"
                      onClick={toggleVisibility1}
                      initial={{ rotate: 0 }}
                      animate={{ rotate: isVisible1 ? 45 : 0 }}
                      transition={{ duration: 0.3 }}
                      >
                      add
                      </motion.span>
                    </span>
                  </div>
                  <motion.div className='price-container'
                    initial={{ opacity: 0, y: -10, height: 0, display: "none" }}
                    animate={{
                      opacity: isVisible1 ? 1 : 0,
                      y: isVisible1 ? 0 : -10,
                      height: isVisible1 ? "auto" : 0,
                      display: isVisible1 ? "block" : "none",
                    }}
                    transition={{ type: 'spring', stiffness: 100, duration: 0.5 }}
                  >
                    <div className='table-row'>
                      <div className='left'>
                        <div className='left-row'>

                          <div className='window-top-left'></div>

                          <div className='table-cell table-header'>
                            <span></span>
                          </div>

                          <div className='window-top-left'></div>

                          <div className='table-cell'>
                            <span className='feature-name'> Design Layout </span>
                            <Tooltip
                              title={<div style={{ fontSize: 16 }}>Elevate your brand with captivating design layouts that inspire.</div>}
                              className="custom-tooltip"
                              enterTouchDelay={0} leaveTouchDelay={1000}
                            >
                            <svg className='info' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.5208 6.90417 10.7125 6.7125C10.9042 6.52083 11 6.28333 11 6C11 5.71667 10.9042 5.47917 10.7125 5.2875C10.5208 5.09583 10.2833 5 10 5C9.71667 5 9.47917 5.09583 9.2875 5.2875C9.09583 5.47917 9 5.71667 9 6C9 6.28333 9.09583 6.52083 9.2875 6.7125C9.47917 6.90417 9.71667 7 10 7ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z" fill="black" />
                            </svg>
                            </Tooltip>
                          </div>

                          <div className='window-top-left'></div>

                          <div className='table-cell'>
                            <span className='feature-name'> Web Pages</span>
                            <Tooltip
                              title={<div style={{ fontSize: 16 }}>Craft a seamless digital journey with expertly designed web pages.</div>}
                              className="custom-tooltip"
                              enterTouchDelay={0} leaveTouchDelay={1000}
                            >
                            <svg className='info' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.5208 6.90417 10.7125 6.7125C10.9042 6.52083 11 6.28333 11 6C11 5.71667 10.9042 5.47917 10.7125 5.2875C10.5208 5.09583 10.2833 5 10 5C9.71667 5 9.47917 5.09583 9.2875 5.2875C9.09583 5.47917 9 5.71667 9 6C9 6.28333 9.09583 6.52083 9.2875 6.7125C9.47917 6.90417 9.71667 7 10 7ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z" fill="black" />
                            </svg>
                            </Tooltip>
                          </div>

                          <div className='window-top-left'></div>

                          <div className='table-cell'>
                            <span className='feature-name'> Revisions</span>
                            <Tooltip
                              title={<div style={{ fontSize: 16 }}>Number of revisions to ensure your website matches your vision perfectly.</div>}
                              className="custom-tooltip"
                              enterTouchDelay={0} leaveTouchDelay={1000}
                            >
                            <svg className='info' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.5208 6.90417 10.7125 6.7125C10.9042 6.52083 11 6.28333 11 6C11 5.71667 10.9042 5.47917 10.7125 5.2875C10.5208 5.09583 10.2833 5 10 5C9.71667 5 9.47917 5.09583 9.2875 5.2875C9.09583 5.47917 9 5.71667 9 6C9 6.28333 9.09583 6.52083 9.2875 6.7125C9.47917 6.90417 9.71667 7 10 7ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z" fill="black" />
                            </svg>
                            </Tooltip>
                          </div>

                          <div className='window-top-left'></div>

                          <div className='table-cell'>
                            <span className='feature-name'> 404 Page</span>
                            <Tooltip
                              title={<div style={{ fontSize: 16 }}>Turn errors into engagement with user-friendly 404 pages.</div>}
                              className="custom-tooltip"
                              enterTouchDelay={0} leaveTouchDelay={1000}
                            >
                            <svg className='info' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.5208 6.90417 10.7125 6.7125C10.9042 6.52083 11 6.28333 11 6C11 5.71667 10.9042 5.47917 10.7125 5.2875C10.5208 5.09583 10.2833 5 10 5C9.71667 5 9.47917 5.09583 9.2875 5.2875C9.09583 5.47917 9 5.71667 9 6C9 6.28333 9.09583 6.52083 9.2875 6.7125C9.47917 6.90417 9.71667 7 10 7ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z" fill="black" />
                            </svg>
                            </Tooltip>
                          </div>

                          <div className='window-top-left'></div>

                          <div className='table-cell'>
                            <span className='feature-name'> Responsive</span>
                            <Tooltip
                              title={<div style={{ fontSize: 16 }}>Optimized for all devices, ensuring a seamless user experience everywhere.</div>}
                              className="custom-tooltip"
                              enterTouchDelay={0} leaveTouchDelay={1000}
                            >
                            <svg className='info' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.5208 6.90417 10.7125 6.7125C10.9042 6.52083 11 6.28333 11 6C11 5.71667 10.9042 5.47917 10.7125 5.2875C10.5208 5.09583 10.2833 5 10 5C9.71667 5 9.47917 5.09583 9.2875 5.2875C9.09583 5.47917 9 5.71667 9 6C9 6.28333 9.09583 6.52083 9.2875 6.7125C9.47917 6.90417 9.71667 7 10 7ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z" fill="black" />
                            </svg>
                            </Tooltip>
                          </div>

                          <div className='window-top-left'></div>

                          <div className='table-cell'>
                            <span className='feature-name'> SSL Secured</span>
                            <Tooltip
                              title={<div style={{ fontSize: 16 }}>Protect user data and enhance trust with SSL-secured websites.</div>}
                              className="custom-tooltip"
                              enterTouchDelay={0} leaveTouchDelay={1000}
                            >
                            <svg className='info' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.5208 6.90417 10.7125 6.7125C10.9042 6.52083 11 6.28333 11 6C11 5.71667 10.9042 5.47917 10.7125 5.2875C10.5208 5.09583 10.2833 5 10 5C9.71667 5 9.47917 5.09583 9.2875 5.2875C9.09583 5.47917 9 5.71667 9 6C9 6.28333 9.09583 6.52083 9.2875 6.7125C9.47917 6.90417 9.71667 7 10 7ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z" fill="black" />
                            </svg>
                            </Tooltip>
                          </div>

                          <div className='window-top-left'></div>

                          <div className='table-cell'>
                            <span className='feature-name'> SEO</span>
                            <Tooltip
                              title={<div style={{ fontSize: 16 }}>Boost visibility and online presence with expert SEO optimization services</div>}
                              className="custom-tooltip"
                              enterTouchDelay={0} leaveTouchDelay={1000}
                            >
                            <svg className='info' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.5208 6.90417 10.7125 6.7125C10.9042 6.52083 11 6.28333 11 6C11 5.71667 10.9042 5.47917 10.7125 5.2875C10.5208 5.09583 10.2833 5 10 5C9.71667 5 9.47917 5.09583 9.2875 5.2875C9.09583 5.47917 9 5.71667 9 6C9 6.28333 9.09583 6.52083 9.2875 6.7125C9.47917 6.90417 9.71667 7 10 7ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z" fill="black" />
                            </svg>
                            </Tooltip>
                          </div>

                          <div className='window-top-left'></div>

                          <div className='table-cell'>
                            <span className='feature-name'> Messenger</span>
                            <Tooltip
                              title={<div style={{ fontSize: 16 }}>Connect with your audience in real-time with seamless Messenger integration.</div>}
                              className="custom-tooltip"
                              enterTouchDelay={0} leaveTouchDelay={1000}
                            >
                            <svg className='info' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.5208 6.90417 10.7125 6.7125C10.9042 6.52083 11 6.28333 11 6C11 5.71667 10.9042 5.47917 10.7125 5.2875C10.5208 5.09583 10.2833 5 10 5C9.71667 5 9.47917 5.09583 9.2875 5.2875C9.09583 5.47917 9 5.71667 9 6C9 6.28333 9.09583 6.52083 9.2875 6.7125C9.47917 6.90417 9.71667 7 10 7ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z" fill="black" />
                            </svg>
                            </Tooltip>
                          </div>

                          <div className='window-top-left'></div>

                          <div className='table-cell'>
                            <span className='feature-name'> Google Analytics</span>
                            <Tooltip
                              title={<div style={{ fontSize: 16 }}>Gain insights into user behavior with integrated Google Analytics for data-driven decisions.</div>}
                              className="custom-tooltip"
                              enterTouchDelay={0} leaveTouchDelay={1000}
                            >
                            <svg className='info' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.5208 6.90417 10.7125 6.7125C10.9042 6.52083 11 6.28333 11 6C11 5.71667 10.9042 5.47917 10.7125 5.2875C10.5208 5.09583 10.2833 5 10 5C9.71667 5 9.47917 5.09583 9.2875 5.2875C9.09583 5.47917 9 5.71667 9 6C9 6.28333 9.09583 6.52083 9.2875 6.7125C9.47917 6.90417 9.71667 7 10 7ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z" fill="black" />
                            </svg>
                            </Tooltip>
                          </div>

                          <div className='window-top-left'></div>

                          <div className='table-cell'>
                            <span className='feature-name'> Light | Dark Theme</span>
                            <Tooltip
                              title={<div style={{ fontSize: 16 }}>Tailor your website's look with both light and dark theme options.</div>}
                              className="custom-tooltip"
                              enterTouchDelay={0} leaveTouchDelay={1000}
                            >
                            <svg className='info' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.5208 6.90417 10.7125 6.7125C10.9042 6.52083 11 6.28333 11 6C11 5.71667 10.9042 5.47917 10.7125 5.2875C10.5208 5.09583 10.2833 5 10 5C9.71667 5 9.47917 5.09583 9.2875 5.2875C9.09583 5.47917 9 5.71667 9 6C9 6.28333 9.09583 6.52083 9.2875 6.7125C9.47917 6.90417 9.71667 7 10 7ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z" fill="black" />
                            </svg>
                            </Tooltip>
                          </div>

                          <div className='window-top-left'></div>

                          <div className='table-cell'>
                            <span className='feature-name'> Page Animations</span>
                            <Tooltip
                              title={<div style={{ fontSize: 16 }}>Elevate user experience with engaging page animations and transitions.</div>}
                              className="custom-tooltip"
                              enterTouchDelay={0} leaveTouchDelay={1000}
                            >
                            <svg className='info' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.5208 6.90417 10.7125 6.7125C10.9042 6.52083 11 6.28333 11 6C11 5.71667 10.9042 5.47917 10.7125 5.2875C10.5208 5.09583 10.2833 5 10 5C9.71667 5 9.47917 5.09583 9.2875 5.2875C9.09583 5.47917 9 5.71667 9 6C9 6.28333 9.09583 6.52083 9.2875 6.7125C9.47917 6.90417 9.71667 7 10 7ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z" fill="black" />
                            </svg>
                            </Tooltip>
                          </div>

                          <div className='window-top-left'></div>

                          <div className='table-cell'>
                            <span className='feature-name'> Page Translations</span>
                            <Tooltip
                              title={<div style={{ fontSize: 16 }}>Expand your reach with multilingual support for seamless page translations.</div>}
                              className="custom-tooltip"
                              enterTouchDelay={0} leaveTouchDelay={1000}
                            >
                            <svg className='info' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.5208 6.90417 10.7125 6.7125C10.9042 6.52083 11 6.28333 11 6C11 5.71667 10.9042 5.47917 10.7125 5.2875C10.5208 5.09583 10.2833 5 10 5C9.71667 5 9.47917 5.09583 9.2875 5.2875C9.09583 5.47917 9 5.71667 9 6C9 6.28333 9.09583 6.52083 9.2875 6.7125C9.47917 6.90417 9.71667 7 10 7ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z" fill="black" />
                            </svg>
                            </Tooltip>
                          </div>

                        </div>
                      </div>


                      <div className='right'>

                        <div className='right-row header'>
                          <div className='table-cell table-header'>
                            <div className='header'>
                              <span className='type'> Basic </span>
                              <div>
                                <span className='price'> 15,000 </span>
                                <span className=''> php </span>
                              </div>
                            </div>
                          </div>
                          <div className='table-cell table-header'>
                            <div className='header'>
                              <span className='type'> Standard </span>
                              <div>
                                <span className='price'> 35,000 </span>
                                <span className=''> php </span>
                              </div>
                            </div>
                          </div>
                          <div className='table-cell table-header'>
                            <div className='header'>
                              <span className='type'> Premium </span>
                              <div>
                                <span className='price'> 75,000 </span>
                                <span className=''> php </span>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div className='window-top-right'></div>

                        <div className='right-row'>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined">check_circle</span>
                            <span className='desc'> Fixed</span>
                          </div>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined">check_circle</span>
                            <span className='desc'> Custom</span>
                          </div>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined">check_circle</span>
                            <span className='desc'> Custom</span>
                          </div>
                        </div>

                        <div className='window-top-right'></div>

                        <div className='right-row'>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined">check_circle</span>
                            <span className='desc'> 5 Pages *</span>
                          </div>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined">check_circle</span>
                            <span className='desc'> 8 Pages *</span>
                          </div>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined">check_circle</span>
                            <span className='desc'> 12 Pages *</span>
                          </div>
                        </div>

                        <div className='window-top-right'></div>

                        <div className='right-row'>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined">check_circle</span>
                            <span className='desc'> 3 Sets</span>
                          </div>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined">check_circle</span>
                            <span className='desc'> 5 Sets</span>
                          </div>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined">check_circle</span>
                            <span className='desc'> 8 Sets</span>
                          </div>
                        </div>

                        <div className='window-top-right'></div>

                        <div className='right-row'>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined">check_circle</span>
                            <span className='desc'> Fixed</span>
                          </div>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined">check_circle</span>
                            <span className='desc'> Custom</span>
                          </div>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined">check_circle</span>
                            <span className='desc'> Custom</span>
                          </div>
                        </div>

                        <div className='window-top-right'></div>

                        <div className='right-row'>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined">check_circle</span>
                          </div>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined">check_circle</span>
                          </div>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined">check_circle</span>
                          </div>
                        </div>

                        <div className='window-top-right'></div>

                        <div className='right-row'>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined">check_circle</span>
                          </div>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined">check_circle</span>
                          </div>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined">check_circle</span>
                          </div>
                        </div>

                        <div className='window-top-right'></div>

                        <div className='right-row'>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined cancel">cancel</span>
                          </div>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined">check_circle</span>
                          </div>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined">check_circle</span>
                          </div>
                        </div>

                        <div className='window-top-right'></div>

                        <div className='right-row'>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined cancel">cancel</span>
                          </div>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined">check_circle</span>
                          </div>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined">check_circle</span>
                          </div>
                        </div>

                        <div className='window-top-right'></div>

                        <div className='right-row'>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined cancel">cancel</span>
                          </div>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined">check_circle</span>
                          </div>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined">check_circle</span>
                          </div>
                        </div>

                        <div className='window-top-right'></div>

                        <div className='right-row'>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined cancel">cancel</span>
                          </div>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined cancel">cancel</span>
                          </div>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined">check_circle</span>
                          </div>
                        </div>

                        <div className='window-top-right'></div>

                        <div className='right-row'>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined cancel">cancel</span>
                          </div>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined cancel">cancel</span>
                          </div>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined">check_circle</span>
                          </div>
                        </div>

                        <div className='window-top-right'></div>

                        <div className='right-row'>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined cancel">cancel</span>
                          </div>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined cancel">cancel</span>
                          </div>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined">check_circle</span>
                            <span className='desc'> 2 Languages *</span>
                          </div>
                        </div>

                      </div>
                    </div>
                    
                    <div className='hr'></div>

                    <div className='table-row'>
                      <div className='left'>
                        <div className='left-row'>

                          <div className='window-top-left'></div>

                          <div className='table-cell table-header'>
                            <span></span>
                          </div>

                          <div className='window-top-left'></div>

                          <div className='table-cell'>
                            <span className='feature-name'> Hosting</span>
                            <Tooltip
                              title={<div style={{ fontSize: 16 }}>Affordable monthly hosting fees for reliable, high-performance web hosting solutions.</div>}
                              className="custom-tooltip"
                              enterTouchDelay={0} leaveTouchDelay={1000}
                            >
                            <svg className='info' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.5208 6.90417 10.7125 6.7125C10.9042 6.52083 11 6.28333 11 6C11 5.71667 10.9042 5.47917 10.7125 5.2875C10.5208 5.09583 10.2833 5 10 5C9.71667 5 9.47917 5.09583 9.2875 5.2875C9.09583 5.47917 9 5.71667 9 6C9 6.28333 9.09583 6.52083 9.2875 6.7125C9.47917 6.90417 9.71667 7 10 7ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z" fill="black" />
                            </svg>
                            </Tooltip>
                          </div>

                          <div className='window-top-left'></div>

                          <div className='table-cell'>
                            <span className='feature-name'> Bandwidth</span>
                            <Tooltip
                              title={<div style={{ fontSize: 16 }}>Generous monthly bandwidth size for seamless, lightning-fast data transfers. <br/> <br/> Adding more media to your site increases the necessary bandwidth for seamless data delivery. </div>}
                              className="custom-tooltip"
                              enterTouchDelay={0} leaveTouchDelay={1000}
                            >
                            <svg className='info' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.5208 6.90417 10.7125 6.7125C10.9042 6.52083 11 6.28333 11 6C11 5.71667 10.9042 5.47917 10.7125 5.2875C10.5208 5.09583 10.2833 5 10 5C9.71667 5 9.47917 5.09583 9.2875 5.2875C9.09583 5.47917 9 5.71667 9 6C9 6.28333 9.09583 6.52083 9.2875 6.7125C9.47917 6.90417 9.71667 7 10 7ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z" fill="black" />
                            </svg>
                            </Tooltip>
                          </div>

                        </div>
                      </div>


                      <div className='right'>

                        <div className='right-row header'>
                          <div className='table-cell table-header'>
                            <div className='header'>
                              <span className='type'> /month </span>
                              <div>
                                <span className='price'> Basic </span>
                              </div>
                            </div>
                          </div>
                          <div className='table-cell table-header'>
                            <div className='header'>
                              <span className='type'> /month </span>
                              <div>
                                <span className='price'> Standard </span>
                              </div>
                            </div>
                          </div>
                          <div className='table-cell table-header'>
                            <div className='header'>
                              <span className='type'> /month </span>
                              <div>
                                <span className='price'> Premium </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='window-top-right'></div>

                        <div className='right-row'>
                          <div className='table-cell'>
                            <span className='desc'> Free</span>
                          </div>
                          <div className='table-cell'>
                            <span className='desc'> 500 php</span>
                          </div>
                          <div className='table-cell'>
                            <span className='desc'> 750 php</span>
                          </div>
                        </div>

                        <div className='window-top-right'></div>

                        <div className='right-row'>
                          <div className='table-cell'>
                            <span className='desc'> 10 GB</span>
                          </div>
                          <div className='table-cell'>
                            <span className='desc'> 50 GB</span>
                          </div>
                          <div className='table-cell'>
                            <span className='desc'> 100 GB</span>
                          </div>
                        </div>

                      </div>
                    </div>

                    <div className='hr'></div>

                    <div className='table-row'>
                      <div className='left'>
                        <div className='left-row'>

                          <div className='window-top-left'></div>

                          <div className='table-cell table-header'>
                            <span></span>
                          </div>

                          <div className='window-top-left'></div>

                          <div className='table-cell'>
                            <span className='feature-name'> Domain</span>
                            <Tooltip
                              title={<div style={{ fontSize: 16 }}>Competitive annual domain prices, based on current market rates, ensuring affordability. <br /><br /> I offer in-house domain management services with 20% fee on top of market price or connect client-owned domain for free.</div>}
                              className="custom-tooltip"
                              enterTouchDelay={0} leaveTouchDelay={1000}
                            >
                            <svg className='info' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.5208 6.90417 10.7125 6.7125C10.9042 6.52083 11 6.28333 11 6C11 5.71667 10.9042 5.47917 10.7125 5.2875C10.5208 5.09583 10.2833 5 10 5C9.71667 5 9.47917 5.09583 9.2875 5.2875C9.09583 5.47917 9 5.71667 9 6C9 6.28333 9.09583 6.52083 9.2875 6.7125C9.47917 6.90417 9.71667 7 10 7ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z" fill="black" />
                            </svg>
                            </Tooltip>
                          </div>

                        </div>
                      </div>


                      <div className='right'>

                        <div className='right-row header'>
                          <div className='table-cell table-header'>
                            <div className='header'>
                              <span className='type'> /year </span>
                              <div>
                                <span className='price'> Basic </span>
                              </div>
                            </div>
                          </div>
                          <div className='table-cell table-header'>
                            <div className='header'>
                              <span className='type'> /year </span>
                              <div>
                                <span className='price'> Standard </span>
                              </div>
                            </div>
                          </div>
                          <div className='table-cell table-header'>
                            <div className='header'>
                              <span className='type'> /year </span>
                              <div>
                                <span className='price'> Premium </span>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div className='window-top-right'></div>

                        <div className='right-row'>
                          <div className='table-cell'>
                            <span className='desc'> Market Price *</span>
                          </div>
                          <div className='table-cell'>
                            <span className='desc'> Market Price *</span>
                          </div>
                          <div className='table-cell'>
                            <span className='desc'> Market Price *</span>
                          </div>
                        </div>

                      </div>
                    </div>

                    <div className='hr'></div>

                    <div className='table-row'>
                      <div className='left'>
                        <div className='left-row'>

                          <div className='window-top-left'></div>

                          <div className='table-cell table-header'>
                            <span></span>
                          </div>

                          <div className='window-top-left'></div>

                          <div className='table-cell'>
                            <span className='feature-name'> Web Page</span>
                            <Tooltip
                              title={<div style={{ fontSize: 16 }}>Additional page fee.</div>}
                              className="custom-tooltip"
                              enterTouchDelay={0} leaveTouchDelay={1000}
                            >
                            <svg className='info' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.5208 6.90417 10.7125 6.7125C10.9042 6.52083 11 6.28333 11 6C11 5.71667 10.9042 5.47917 10.7125 5.2875C10.5208 5.09583 10.2833 5 10 5C9.71667 5 9.47917 5.09583 9.2875 5.2875C9.09583 5.47917 9 5.71667 9 6C9 6.28333 9.09583 6.52083 9.2875 6.7125C9.47917 6.90417 9.71667 7 10 7ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z" fill="black" />
                            </svg>
                            </Tooltip>
                          </div>

                          <div className='window-top-left'></div>

                          <div className='table-cell'>
                            <span className='feature-name'> Language</span>
                            <Tooltip
                              title={<div style={{ fontSize: 16 }}>Additional language translation fee.</div>}
                              className="custom-tooltip"
                              enterTouchDelay={0} leaveTouchDelay={1000}
                            >
                            <svg className='info' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.5208 6.90417 10.7125 6.7125C10.9042 6.52083 11 6.28333 11 6C11 5.71667 10.9042 5.47917 10.7125 5.2875C10.5208 5.09583 10.2833 5 10 5C9.71667 5 9.47917 5.09583 9.2875 5.2875C9.09583 5.47917 9 5.71667 9 6C9 6.28333 9.09583 6.52083 9.2875 6.7125C9.47917 6.90417 9.71667 7 10 7ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z" fill="black" />
                            </svg>
                            </Tooltip>
                          </div>

                        </div>
                      </div>


                      <div className='right'>

                        <div className='right-row header'>
                          <div className='table-cell table-header'>
                            <div className='header'>
                              <span className='type'> /add-ons </span>
                              <div>
                                <span className='price'> Basic </span>
                              </div>
                            </div>
                          </div>
                          <div className='table-cell table-header'>
                            <div className='header'>
                              <span className='type'> /add-ons </span>
                              <div>
                                <span className='price'> Standard </span>
                              </div>
                            </div>
                          </div>
                          <div className='table-cell table-header'>
                            <div className='header'>
                              <span className='type'> /add-ons </span>
                              <div>
                                <span className='price'> Premium </span>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div className='window-top-right'></div>

                        <div className='right-row'>
                          <div className='table-cell'>
                            <span className='desc'> 1,000 php</span>
                          </div>
                          <div className='table-cell'>
                            <span className='desc'> 4,000 php</span>
                          </div>
                          <div className='table-cell'>
                            <span className='desc'> 8,000 php</span>
                          </div>
                        </div>

                        <div className='window-top-right'></div>

                        <div className='right-row'>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined cancel">cancel</span>
                          </div>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined cancel">cancel</span>
                          </div>
                          <div className='table-cell'>
                            <span className='desc'> 5,000 php</span>
                          </div>
                        </div>

                      </div>
                    </div>

                  </motion.div>
                </div>
              </div>

              {/* end of first dropdown */}

              {/* <div className='wrapper'>
                <div className='each-service'>
                  <div className='title-control' onClick={toggleVisibility2}>
                    <span className='title-name'>
                      Static Website Plans
                    </span>
                    <span className='contol-icon-holder'>
                      <motion.span class="material-symbols-outlined contol-icon"
                      onClick={toggleVisibility2}
                      initial={{ rotate: 0 }}
                      animate={{ rotate: isVisible2 ? 45 : 0 }}
                      transition={{ duration: 0.3 }}
                      >
                      add
                      </motion.span>
                    </span>
                  </div>
                  <motion.div className='price-container'
                    initial={{ opacity: 0, y: -10, height: 0, display: "none" }}
                    animate={{
                      opacity: isVisible2 ? 1 : 0,
                      y: isVisible2 ? 0 : -10,
                      height: isVisible2 ? "auto" : 0,
                      display: isVisible2 ? "block" : "none",
                    }}
                    transition={{ type: 'spring', stiffness: 100, duration: 0.5 }}
                  >
                    <div className='table-row'>
                      <div className='left'>
                        <div className='left-row'>

                          <div className='window-top-left'></div>

                          <div className='table-cell table-header'>
                            <span></span>
                          </div>

                          <div className='window-top-left'></div>

                          <div className='table-cell'>
                            <span className='feature-name'> Design Layout </span>
                          </div>

                          <div className='window-top-left'></div>

                          <div className='table-cell'>
                            <span className='feature-name'> Web Pages</span>
                            <Tooltip
                              title={<div style={{ fontSize: 16 }}>Lorem ipsum dolor.</div>}
                              className="custom-tooltip"
                              enterTouchDelay={0} leaveTouchDelay={1000}
                            >
                            <svg className='info' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.5208 6.90417 10.7125 6.7125C10.9042 6.52083 11 6.28333 11 6C11 5.71667 10.9042 5.47917 10.7125 5.2875C10.5208 5.09583 10.2833 5 10 5C9.71667 5 9.47917 5.09583 9.2875 5.2875C9.09583 5.47917 9 5.71667 9 6C9 6.28333 9.09583 6.52083 9.2875 6.7125C9.47917 6.90417 9.71667 7 10 7ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z" fill="black" />
                            </svg>
                            </Tooltip>
                          </div>

                        </div>
                      </div>


                      <div className='right'>

                        <div className='right-row header'>
                          <div className='table-cell table-header'>
                            <div className='header'>
                              <span className='type'> Basic </span>
                              <div>
                                <span className='price'> 15,000 </span>
                                <span className=''> php </span>
                              </div>
                            </div>
                          </div>
                          <div className='table-cell table-header'>
                            <div className='header'>
                              <span className='type'> Standard </span>
                              <div>
                                <span className='price'> 30,000 </span>
                                <span className=''> php </span>
                              </div>
                            </div>
                          </div>
                          <div className='table-cell table-header'>
                            <div className='header'>
                              <span className='type'> Premium </span>
                              <div>
                                <span className='price'> 80,000 </span>
                                <span className=''> php </span>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div className='window-top-right'></div>

                        <div className='right-row'>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined">check_circle</span>
                            <span className='desc'> Fixed</span>
                          </div>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined">check_circle</span>
                            <span className='desc'> Custom</span>
                          </div>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined">check_circle</span>
                            <span className='desc'> Custom</span>
                          </div>
                        </div>

                        <div className='window-top-right'></div>

                        <div className='right-row'>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined">check_circle</span>
                            <span className='desc'> 5 Pages *</span>
                          </div>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined">check_circle</span>
                            <span className='desc'> 10 Pages *</span>
                          </div>
                          <div className='table-cell'>
                            <span class="material-symbols-outlined">check_circle</span>
                            <span className='desc'> 15 Pages *</span>
                          </div>
                        </div>

                      </div>
                    </div>

                  </motion.div>
                </div>
              </div> */}

            </div>

            
            
          </section>
        </div>
        </ThemeProvider>
        
    )
}

export default Pricing;