import React, { useState, useRef, useEffect } from 'react'

import styled, { ThemeProvider } from 'styled-components'
import { lightTheme, darkTheme, GlobalStyles } from '../themes.js'
import { useHistory } from "react-router-dom";
import './styles/terms.scss'

function Terms(themeData) {

  useEffect(() => {
    document.title = 'Terms & Conditions • Jins Gonzales Design';
  }, []);

    const history = useHistory();
  
    return (
        <ThemeProvider theme={themeData.theme === 'light' ? lightTheme : darkTheme}>
        <GlobalStyles />
        <div id='terms'>

          <section className="terms section-top section-bottom">
            <div className='pagetopMargin'></div>
            <div className="wrapper">
                <div className="flex flex-column">
                    <span className="title">Terms & Conditions</span>
                </div>
            </div>
            <div className="wrapper">
              <div className='terms-content'>
                <div className='terms-title'>Acceptance of Terms</div>
                <br/>
                <div className='terms-info'>By accessing or using the services provided by Jins Gonzales Design (referred to as "we," "us," or "our"), you agree to be bound by these Terms and Conditions. If you do not agree to these Terms and Conditions, please refrain from using our services.</div>
                <br/>
                <div className='terms-title'>Scope of Services</div>
                <br/>
                <div className='terms-info'>Jins Gonzales Design offers web design and development services, including but not limited to website creation, redesign, maintenance, and related services.</div>
                <br/>
                <div className='terms-title'>Client Responsibilities</div>
                <br/>
                <div className='terms-info'>As a client of Jins Gonzales Design, you agree to:</div>
                <br/>
                <div className='terms-info'>
                  <ul>
                    <li>Provide accurate and complete information regarding your project requirements.</li>
                    <li>Cooperate in a timely manner by responding to our requests for information or feedback.</li>
                    <li>Comply with payment terms as outlined in our project agreements.</li>
                  </ul> 
                </div>
                <br/>
                <div className='terms-title'>Project Agreements</div>
                <br/>
                <div className='terms-info'>Each project undertaken by Jins Gonzales Design will be governed by a separate project agreement. This agreement will detail project scope, timelines, deliverables, and payment terms.</div>
                <br/>
                <div className='terms-title'>Intellectual Property</div>
                <br/>
                <div className='terms-info'>Upon full payment, Jins Gonzales Design grants you a non-exclusive, non-transferable license to use the deliverables created for your project. We retain the right to display the project in our portfolio and marketing materials.</div>
                <br/>
                <div className='terms-title'>Confidentiality</div>
                <br/>
                <div className='terms-info'>Both parties agree to maintain the confidentiality of sensitive information shared during the project, including but not limited to proprietary business information.</div>
                <br/>
                <div className='terms-title'>Payment Terms</div>
                <br/>
                <div className='terms-info'>Payment terms and schedules will be outlined in each project agreement. Failure to adhere to these terms may result in project delays or suspension.</div>
                <br/>
                <div className='terms-title'>Refund Policy</div>
                <br/>
                <div className='terms-info'>Refund policies are specified in individual project agreements and are subject to project-specific terms.</div>
                <br/>
                <div className='terms-title'>Termination</div>
                <br/>
                <div className='terms-info'>Either party may terminate a project in accordance with the terms outlined in the project agreement.</div>
                <br/>
                <div className='terms-title'>Limitation of Liability</div>
                <br/>
                <div className='terms-info'>Jins Gonzales Design is not liable for any indirect, special, incidental, or consequential damages arising from the use of our services.</div>
                <br/>
                <div className='terms-title'>Modifications</div>
                <br/>
                <div className='terms-info'>We reserve the right to modify these Terms and Conditions at any time. Updated versions will be posted on our website.</div>
                <br/>
                <div className='terms-info'>Thank you for choosing Jins Gonzales Design. We look forward to serving you.</div>

              </div>
            </div>
          </section>

        </div>
        </ThemeProvider>
        
    )
}

export default Terms;