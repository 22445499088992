import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { Tooltip } from '@mui/material';
import './styles/home.scss'
import ReactGA from "react-ga4";

import styled, { ThemeProvider } from 'styled-components'
import { lightTheme, darkTheme, GlobalStyles } from '../themes.js'

import arLight from '../assets/img/arLight.svg'
import arDark from '../assets/img/arDark.svg'

import homeProjects from '../data/homeProjects.json'
import homeAR from '../data/homeAR.json'

// Initialize ReactGA with your Measurement ID
ReactGA.initialize('G-WER1Z42GL5');

function Home(themeData) {

    useEffect(() => {
      document.title = 'Jins Gonzales Design • Web Designer & Developer Philippines';
    }, []);

    // function to navigate to path
    const history = useHistory();
    const qrAR = themeData.theme === 'light' ? arDark : arLight;

    const a = document.createElement("a");
    var clickURL = "none"
    if (a.relList.supports("ar")) {
      console.log("AR IS AVAILABLE")
      clickURL = "auto"
    } else {
      console.log("AR IS NOT AVAILABLE")
      clickURL = "none"
    }

    // track projects button click google analytics
    const visitSiteButtonClick = (card) => {
      console.log('REACT-GA4 VIEW PROJECT BUTTON CLICKED')
      // console.log('JSON DATA', card)
      ReactGA.event({
        category: card,
        action: "click on projects",
        label: card,
      })
    };
    // useEffect(() => {
    //   visitSiteButtonClick();
    // }, []);

    return (
        <ThemeProvider theme={themeData.theme === 'light' ? lightTheme : darkTheme}>
        <GlobalStyles />
        <div id="home">

            {/* <section className='banner'>
                    <div className='banner-container'>
                      <div className='mobile-padding'></div>
                      <div className='motto'>
                        <div className='motto-container'>
                          <span className="create">
                              <svg 
                              id="create"
                              viewBox="0 0 323 77" 
                              fill="none" 
                              xmlns="http://www.w3.org/2000/svg">
                                  <path className="create-sv-path" d="M12.3105 74H2.93555V2.90625H12.3105V74Z" stroke="white" strokeWidth="2" mask="url(#path-1-outside-1)" />
                                  <path className="create-sv-path" d="M74.0781 67.6035C77.3008 67.6035 80.1165 66.627 82.5254 64.6738C84.9342 62.7207 86.2689 60.2793 86.5293 57.3496H95.0742C94.9115 60.377 93.8698 63.2578 91.9492 65.9922C90.0286 68.7266 87.457 70.9076 84.2344 72.5352C81.0443 74.1628 77.6589 74.9766 74.0781 74.9766C66.8841 74.9766 61.1549 72.584 56.8906 67.7988C52.6589 62.9811 50.543 56.4056 50.543 48.0723V46.5586C50.543 41.4154 51.487 36.8418 53.375 32.8379C55.263 28.834 57.9648 25.7253 61.4805 23.5117C65.0286 21.2982 69.2116 20.1914 74.0293 20.1914C79.9538 20.1914 84.8691 21.9655 88.7754 25.5137C92.7142 29.0618 94.8138 33.668 95.0742 39.332H86.5293C86.2689 35.9141 84.9668 33.1146 82.623 30.9336C80.3118 28.7201 77.4473 27.6133 74.0293 27.6133C69.4395 27.6133 65.875 29.2734 63.3359 32.5938C60.8294 35.8815 59.5762 40.6504 59.5762 46.9004V48.6094C59.5762 54.6966 60.8294 59.3841 63.3359 62.6719C65.8424 65.9596 69.4232 67.6035 74.0781 67.6035Z" stroke="white" strokeWidth="2" mask="url(#path-1-outside-1)" />
                                  <path className="create-sv-path" d="M130.768 29.2734C129.4 29.0456 127.919 28.9316 126.324 28.9316C120.4 28.9316 116.38 31.4544 114.264 36.5V74H105.23V21.168H114.02L114.166 27.2715C117.128 22.5514 121.327 20.1914 126.764 20.1914C128.521 20.1914 129.856 20.4193 130.768 20.875V29.2734Z" stroke="white" strokeWidth="2" mask="url(#path-1-outside-1)" />
                                  <path className="create-sv-path" d="M160.064 74.9766C152.903 74.9766 147.076 72.6328 142.584 67.9453C138.092 63.2253 135.846 56.9264 135.846 49.0488V47.3887C135.846 42.1478 136.839 37.4766 138.824 33.375C140.842 29.2409 143.642 26.0182 147.223 23.707C150.836 21.3633 154.742 20.1914 158.941 20.1914C165.81 20.1914 171.148 22.4538 174.957 26.9785C178.766 31.5033 180.67 37.9811 180.67 46.4121V50.1719H144.879C145.009 55.3802 146.523 59.5957 149.42 62.8184C152.35 66.0085 156.061 67.6035 160.553 67.6035C163.743 67.6035 166.445 66.9525 168.658 65.6504C170.872 64.3483 172.809 62.623 174.469 60.4746L179.986 64.7715C175.559 71.5749 168.919 74.9766 160.064 74.9766ZM158.941 27.6133C155.296 27.6133 152.236 28.9479 149.762 31.6172C147.288 34.2539 145.758 37.9648 145.172 42.75H171.637V42.0664C171.376 37.4766 170.139 33.9284 167.926 31.4219C165.712 28.8828 162.717 27.6133 158.941 27.6133Z" stroke="white" strokeWidth="2" mask="url(#path-1-outside-1)" />
                                  <path className="create-sv-path" d="M223.785 74C223.264 72.9583 222.841 71.1029 222.516 68.4336C218.316 72.7956 213.303 74.9766 207.477 74.9766C202.268 74.9766 197.988 73.5117 194.635 70.582C191.314 67.6198 189.654 63.8763 189.654 59.3516C189.654 53.8503 191.738 49.5859 195.904 46.5586C200.104 43.4987 205.995 41.9688 213.58 41.9688H222.369V37.8184C222.369 34.6608 221.425 32.1543 219.537 30.2988C217.649 28.4108 214.866 27.4668 211.188 27.4668C207.965 27.4668 205.263 28.2806 203.082 29.9082C200.901 31.5358 199.811 33.5052 199.811 35.8164H190.729C190.729 33.1797 191.656 30.6406 193.512 28.1992C195.4 25.7253 197.939 23.7721 201.129 22.3398C204.352 20.9076 207.883 20.1914 211.725 20.1914C217.812 20.1914 222.581 21.7214 226.031 24.7812C229.482 27.8086 231.272 31.9915 231.402 37.3301V61.6465C231.402 66.4967 232.021 70.3542 233.258 73.2188V74H223.785ZM208.795 67.1152C211.627 67.1152 214.312 66.3828 216.852 64.918C219.391 63.4531 221.23 61.5488 222.369 59.2051V48.3652H215.289C204.221 48.3652 198.688 51.6042 198.688 58.082C198.688 60.9141 199.632 63.1276 201.52 64.7227C203.408 66.3177 205.833 67.1152 208.795 67.1152Z" stroke="white" strokeWidth="2" mask="url(#path-1-outside-1)" />
                                  <path className="create-sv-path" d="M257.818 8.375V21.168H267.682V28.1504H257.818V60.9141C257.818 63.0299 258.258 64.625 259.137 65.6992C260.016 66.7409 261.513 67.2617 263.629 67.2617C264.671 67.2617 266.103 67.0664 267.926 66.6758V74C265.549 74.651 263.238 74.9766 260.992 74.9766C256.956 74.9766 253.912 73.7559 251.861 71.3145C249.811 68.873 248.785 65.4062 248.785 60.9141V28.1504H239.166V21.168H248.785V8.375H257.818Z" stroke="white" strokeWidth="2" mask="url(#path-1-outside-1)" />
                                  <path className="create-sv-path" d="M300.201 74.9766C293.04 74.9766 287.213 72.6328 282.721 67.9453C278.229 63.2253 275.982 56.9264 275.982 49.0488V47.3887C275.982 42.1478 276.975 37.4766 278.961 33.375C280.979 29.2409 283.779 26.0182 287.359 23.707C290.973 21.3633 294.879 20.1914 299.078 20.1914C305.947 20.1914 311.285 22.4538 315.094 26.9785C318.902 31.5033 320.807 37.9811 320.807 46.4121V50.1719H285.016C285.146 55.3802 286.66 59.5957 289.557 62.8184C292.486 66.0085 296.197 67.6035 300.689 67.6035C303.88 67.6035 306.581 66.9525 308.795 65.6504C311.008 64.3483 312.945 62.623 314.605 60.4746L320.123 64.7715C315.696 71.5749 309.055 74.9766 300.201 74.9766ZM299.078 27.6133C295.432 27.6133 292.372 28.9479 289.898 31.6172C287.424 34.2539 285.895 37.9648 285.309 42.75H311.773V42.0664C311.513 37.4766 310.276 33.9284 308.062 31.4219C305.849 28.8828 302.854 27.6133 299.078 27.6133Z" stroke="white" strokeWidth="2" mask="url(#path-1-outside-1)" />
                              </svg>
                          </span>
                          <span className="experiences">
                              <svg 
                              id="exp"
                              viewBox="0 0 554 98" 
                              fill="none" 
                              xmlns="http://www.w3.org/2000/svg">
                                  <path className="create-sv-path" d="M26.7598 75.9766C19.5983 75.9766 13.7715 73.6328 9.2793 68.9453C4.78711 64.2253 2.54102 57.9264 2.54102 50.0488V48.3887C2.54102 43.1478 3.53385 38.4766 5.51953 34.375C7.53776 30.2409 10.3372 27.0182 13.918 24.707C17.5312 22.3633 21.4375 21.1914 25.6367 21.1914C32.5052 21.1914 37.8438 23.4538 41.6523 27.9785C45.4609 32.5033 47.3652 38.9811 47.3652 47.4121V51.1719H11.5742C11.7044 56.3802 13.2181 60.5957 16.1152 63.8184C19.0449 67.0085 22.7559 68.6035 27.248 68.6035C30.4382 68.6035 33.14 67.9525 35.3535 66.6504C37.5671 65.3483 39.5039 63.623 41.1641 61.4746L46.6816 65.7715C42.2546 72.5749 35.6139 75.9766 26.7598 75.9766ZM25.6367 28.6133C21.9909 28.6133 18.931 29.9479 16.457 32.6172C13.9831 35.2539 12.4531 38.9648 11.8672 43.75H38.332V43.0664C38.0716 38.4766 36.8346 34.9284 34.6211 32.4219C32.4076 29.8828 29.4128 28.6133 25.6367 28.6133Z" stroke="white" strokeWidth="2" mask="url(#path-1-outside-1)" />
                                  <path className="create-sv-path" d="M75.5879 41.4551L87.3066 22.168H97.8535L80.5684 48.291L98.3906 75H87.9414L75.7344 55.2246L63.5273 75H53.0293L70.8516 48.291L53.5664 22.168H64.0156L75.5879 41.4551Z" stroke="white" strokeWidth="2" mask="url(#path-1-outside-1)" />
                                  <path className="create-sv-path" d="M152.102 49.1699C152.102 57.2103 150.262 63.6882 146.584 68.6035C142.906 73.5189 137.925 75.9766 131.643 75.9766C125.23 75.9766 120.184 73.9421 116.506 69.873V95.3125H107.473V22.168H115.725L116.164 28.0273C119.842 23.4701 124.953 21.1914 131.496 21.1914C137.844 21.1914 142.857 23.584 146.535 28.3691C150.246 33.1543 152.102 39.8112 152.102 48.3398V49.1699ZM143.068 48.1445C143.068 42.1875 141.799 37.4837 139.26 34.0332C136.721 30.5827 133.238 28.8574 128.811 28.8574C123.342 28.8574 119.24 31.2826 116.506 36.1328V61.377C119.208 66.1947 123.342 68.6035 128.908 68.6035C133.238 68.6035 136.672 66.8945 139.211 63.4766C141.783 60.026 143.068 54.9154 143.068 48.1445Z" stroke="white" strokeWidth="2" mask="url(#path-1-outside-1)" />
                                  <path className="create-sv-path" d="M185.549 75.9766C178.387 75.9766 172.561 73.6328 168.068 68.9453C163.576 64.2253 161.33 57.9264 161.33 50.0488V48.3887C161.33 43.1478 162.323 38.4766 164.309 34.375C166.327 30.2409 169.126 27.0182 172.707 24.707C176.32 22.3633 180.227 21.1914 184.426 21.1914C191.294 21.1914 196.633 23.4538 200.441 27.9785C204.25 32.5033 206.154 38.9811 206.154 47.4121V51.1719H170.363C170.493 56.3802 172.007 60.5957 174.904 63.8184C177.834 67.0085 181.545 68.6035 186.037 68.6035C189.227 68.6035 191.929 67.9525 194.143 66.6504C196.356 65.3483 198.293 63.623 199.953 61.4746L205.471 65.7715C201.044 72.5749 194.403 75.9766 185.549 75.9766ZM184.426 28.6133C180.78 28.6133 177.72 29.9479 175.246 32.6172C172.772 35.2539 171.242 38.9648 170.656 43.75H197.121V43.0664C196.861 38.4766 195.624 34.9284 193.41 32.4219C191.197 29.8828 188.202 28.6133 184.426 28.6133Z" stroke="white" strokeWidth="2" mask="url(#path-1-outside-1)" />
                                  <path className="create-sv-path" d="M242.189 30.2734C240.822 30.0456 239.341 29.9316 237.746 29.9316C231.822 29.9316 227.801 32.4544 225.686 37.5V75H216.652V22.168H225.441L225.588 28.2715C228.55 23.5514 232.749 21.1914 238.186 21.1914C239.943 21.1914 241.278 21.4193 242.189 21.875V30.2734Z" stroke="white" strokeWidth="2" mask="url(#path-1-outside-1)" />
                                  <path className="create-sv-path" d="M260.354 75H251.32V22.168H260.354V75ZM250.588 8.1543C250.588 6.68945 251.027 5.45247 251.906 4.44336C252.818 3.43424 254.152 2.92969 255.91 2.92969C257.668 2.92969 259.003 3.43424 259.914 4.44336C260.826 5.45247 261.281 6.68945 261.281 8.1543C261.281 9.61914 260.826 10.8398 259.914 11.8164C259.003 12.793 257.668 13.2812 255.91 13.2812C254.152 13.2812 252.818 12.793 251.906 11.8164C251.027 10.8398 250.588 9.61914 250.588 8.1543Z" stroke="white" strokeWidth="2" mask="url(#path-1-outside-1)" />
                                  <path className="create-sv-path" d="M296.779 75.9766C289.618 75.9766 283.791 73.6328 279.299 68.9453C274.807 64.2253 272.561 57.9264 272.561 50.0488V48.3887C272.561 43.1478 273.553 38.4766 275.539 34.375C277.557 30.2409 280.357 27.0182 283.938 24.707C287.551 22.3633 291.457 21.1914 295.656 21.1914C302.525 21.1914 307.863 23.4538 311.672 27.9785C315.48 32.5033 317.385 38.9811 317.385 47.4121V51.1719H281.594C281.724 56.3802 283.238 60.5957 286.135 63.8184C289.064 67.0085 292.775 68.6035 297.268 68.6035C300.458 68.6035 303.16 67.9525 305.373 66.6504C307.587 65.3483 309.523 63.623 311.184 61.4746L316.701 65.7715C312.274 72.5749 305.633 75.9766 296.779 75.9766ZM295.656 28.6133C292.01 28.6133 288.951 29.9479 286.477 32.6172C284.003 35.2539 282.473 38.9648 281.887 43.75H308.352V43.0664C308.091 38.4766 306.854 34.9284 304.641 32.4219C302.427 29.8828 299.432 28.6133 295.656 28.6133Z" stroke="white" strokeWidth="2" mask="url(#path-1-outside-1)" />
                                  <path className="create-sv-path" d="M336.428 22.168L336.721 28.8086C340.757 23.7305 346.031 21.1914 352.541 21.1914C363.706 21.1914 369.338 27.4902 369.436 40.0879V75H360.402V40.0391C360.37 36.2305 359.491 33.4147 357.766 31.5918C356.073 29.7689 353.42 28.8574 349.807 28.8574C346.877 28.8574 344.305 29.6387 342.092 31.2012C339.878 32.7637 338.153 34.8145 336.916 37.3535V75H327.883V22.168H336.428Z" stroke="white" strokeWidth="2" mask="url(#path-1-outside-1)" />
                                  <path className="create-sv-path" d="M404.25 68.6035C407.473 68.6035 410.288 67.627 412.697 65.6738C415.106 63.7207 416.441 61.2793 416.701 58.3496H425.246C425.083 61.377 424.042 64.2578 422.121 66.9922C420.201 69.7266 417.629 71.9076 414.406 73.5352C411.216 75.1628 407.831 75.9766 404.25 75.9766C397.056 75.9766 391.327 73.584 387.062 68.7988C382.831 63.9811 380.715 57.4056 380.715 49.0723V47.5586C380.715 42.4154 381.659 37.8418 383.547 33.8379C385.435 29.834 388.137 26.7253 391.652 24.5117C395.201 22.2982 399.383 21.1914 404.201 21.1914C410.126 21.1914 415.041 22.9655 418.947 26.5137C422.886 30.0618 424.986 34.668 425.246 40.332H416.701C416.441 36.9141 415.139 34.1146 412.795 31.9336C410.484 29.7201 407.619 28.6133 404.201 28.6133C399.611 28.6133 396.047 30.2734 393.508 33.5938C391.001 36.8815 389.748 41.6504 389.748 47.9004V49.6094C389.748 55.6966 391.001 60.3841 393.508 63.6719C396.014 66.9596 399.595 68.6035 404.25 68.6035Z" stroke="white" strokeWidth="2" mask="url(#path-1-outside-1)" />
                                  <path className="create-sv-path" d="M457.326 75.9766C450.165 75.9766 444.338 73.6328 439.846 68.9453C435.354 64.2253 433.107 57.9264 433.107 50.0488V48.3887C433.107 43.1478 434.1 38.4766 436.086 34.375C438.104 30.2409 440.904 27.0182 444.484 24.707C448.098 22.3633 452.004 21.1914 456.203 21.1914C463.072 21.1914 468.41 23.4538 472.219 27.9785C476.027 32.5033 477.932 38.9811 477.932 47.4121V51.1719H442.141C442.271 56.3802 443.785 60.5957 446.682 63.8184C449.611 67.0085 453.322 68.6035 457.814 68.6035C461.005 68.6035 463.706 67.9525 465.92 66.6504C468.133 65.3483 470.07 63.623 471.73 61.4746L477.248 65.7715C472.821 72.5749 466.18 75.9766 457.326 75.9766ZM456.203 28.6133C452.557 28.6133 449.497 29.9479 447.023 32.6172C444.549 35.2539 443.02 38.9648 442.434 43.75H468.898V43.0664C468.638 38.4766 467.401 34.9284 465.188 32.4219C462.974 29.8828 459.979 28.6133 456.203 28.6133Z" stroke="white" strokeWidth="2" mask="url(#path-1-outside-1)" />
                                  <path className="create-sv-path" d="M519.191 60.9863C519.191 58.5449 518.264 56.6569 516.408 55.3223C514.585 53.9551 511.379 52.7832 506.789 51.8066C502.232 50.8301 498.602 49.6582 495.9 48.291C493.231 46.9238 491.245 45.2962 489.943 43.4082C488.674 41.5202 488.039 39.2741 488.039 36.6699C488.039 32.3405 489.862 28.6784 493.508 25.6836C497.186 22.6888 501.874 21.1914 507.57 21.1914C513.56 21.1914 518.41 22.7376 522.121 25.8301C525.865 28.9225 527.736 32.8776 527.736 37.6953H518.654C518.654 35.2214 517.596 33.0892 515.48 31.2988C513.397 29.5085 510.76 28.6133 507.57 28.6133C504.283 28.6133 501.711 29.3294 499.855 30.7617C498 32.194 497.072 34.0658 497.072 36.377C497.072 38.5579 497.935 40.2018 499.66 41.3086C501.385 42.4154 504.494 43.4733 508.986 44.4824C513.511 45.4915 517.173 46.696 519.973 48.0957C522.772 49.4954 524.839 51.1882 526.174 53.1738C527.541 55.127 528.225 57.5195 528.225 60.3516C528.225 65.0716 526.337 68.8639 522.561 71.7285C518.785 74.5605 513.885 75.9766 507.863 75.9766C503.632 75.9766 499.888 75.2279 496.633 73.7305C493.378 72.2331 490.822 70.1497 488.967 67.4805C487.144 64.7786 486.232 61.8652 486.232 58.7402H495.266C495.428 61.7676 496.633 64.1764 498.879 65.9668C501.158 67.7246 504.152 68.6035 507.863 68.6035C511.281 68.6035 514.016 67.9199 516.066 66.5527C518.15 65.153 519.191 63.2975 519.191 60.9863Z" stroke="white" strokeWidth="2" mask="url(#path-1-outside-1)" />
                                  <path className="create-sv-path" d="M540.188 70.2637C540.188 68.7012 540.643 67.3991 541.555 66.3574C542.499 65.3158 543.898 64.7949 545.754 64.7949C547.609 64.7949 549.009 65.3158 549.953 66.3574C550.93 67.3991 551.418 68.7012 551.418 70.2637C551.418 71.7611 550.93 73.0143 549.953 74.0234C549.009 75.0326 547.609 75.5371 545.754 75.5371C543.898 75.5371 542.499 75.0326 541.555 74.0234C540.643 73.0143 540.188 71.7611 540.188 70.2637Z" stroke="white" strokeWidth="2" mask="url(#path-1-outside-1)" />
                              </svg>
                          </span>
                        </div>
                      </div>
                      <div className='graphic'>
                        <motion.img
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5 }} 
                        className='graphic-grid' src={gridURL} ></motion.img>
                        <motion.div
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 1, delay: 0.25 }} 
                        className='circle1'></motion.div>
                        <motion.div 
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 1, delay: 0.5 }} 
                        className='circle2'></motion.div>
                        <motion.div 
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 1, delay: 0.75 }} 
                        className='circle3'></motion.div>
                        <motion.div 
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 1, delay: 1 }} 
                        className='circle4'></motion.div>
                        <motion.img
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 2, delay: 1 }}
                        className='graphic-body' src={graphicURL} ></motion.img>
                        
                      </div>
                    </div>
            </section> */}

            <section className='banner'>
              <div className='wrapper'>
                <div className='banner-container'>
                  <div className='logo'>
                    <svg width="1024" height="1024" viewBox="0 0 1024 1024" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_402_2)">
                      <path d="M844 0H180C80.59 0 0 80.59 0 180V844C0 943.41 80.59 1024 180 1024H844C943.41 1024 1024 943.41 1024 844V180C1024 80.59 943.41 0 844 0ZM394 582.81C394 625.41 382 658.22 358.02 681.25C334.03 704.28 301.7 715.79 261.02 715.79C220.34 715.79 184.26 703.7 158.55 679.52C132.83 655.34 119.98 621 119.98 576.48H217.84C217.84 593.37 221.29 606.13 228.2 614.76C235.11 623.39 245.08 627.71 258.13 627.71C270.02 627.71 279.23 623.88 285.76 616.2C292.28 608.53 295.55 597.4 295.55 582.81V307.64H393.99V582.81H394ZM690.17 686.43C658.12 703.32 620.99 711.76 578.78 711.76H427.38V307.64H578.78C621.38 307.64 658.6 316.09 690.46 332.97C722.31 349.86 746.96 373.55 764.43 404.06C781.89 434.57 790.62 469.78 790.62 509.69C790.62 549.6 781.89 584.34 764.43 615.04C746.96 645.75 722.21 669.54 690.17 686.42V686.43ZM887.61 701.11C876.67 711.28 862.76 716.37 845.88 716.37C829 716.37 814.51 711.29 803.57 701.11C792.63 690.95 787.16 678.37 787.16 663.4C787.16 648.43 792.63 635.19 803.57 624.83C814.51 614.47 828.61 609.29 845.88 609.29C863.15 609.29 876.68 614.47 887.61 624.83C898.55 635.19 904.02 648.05 904.02 663.4C904.02 678.75 898.55 690.94 887.61 701.11Z" fill="white"/>
                      <path d="M572.44 391.69H525.81V626.56H572.44C609.66 626.56 638.64 616.4 659.36 596.05C680.08 575.71 690.45 546.93 690.45 509.7C690.45 472.47 680.09 443.5 659.36 422.78C638.64 402.06 609.66 391.69 572.44 391.69Z" fill="white"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_402_2">
                      <rect width="1024" height="1024" fill="white"/>
                      </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className='motto'>
                    <span>
                      Creating Experiences.
                    </span>
                  </div>
                  

                </div>
              </div>
            </section>

            {/* <section className="featured">
                <div className="wrapper">
                    <div className="flex flex-column">
                        <span className="title">Featured</span>
                    </div>

                </div>
            </section> */}

            <section className="projects section-top-websites">
                <div className="wrapper">
                    <div className="flex flex-column">
                        <span className="title">Websites</span>
                    </div>
                </div>
                <div className="wrapper">
                    <div className="flex flex-column">
                        <span onClick={() => history.push('/websites')} className="subtitle">
                            <span>See all</span>
                            <svg height="15" viewBox="0 0 42 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M41.1493 33.4018L5.73415 1.12786C4.572 -0.0283801 2.68725 -0.0283801 1.52511 1.12786C0.362968 2.28402 0.362968 4.16001 1.52511 5.31626L34.8049 35.6465L1.52806 65.9767C0.365916 67.1329 0.365916 69.0089 1.52806 70.1651C2.6902 71.3212 4.57495 71.3212 5.73701 70.1651L41.1522 37.8911C41.7716 37.2747 42.0372 36.4576 41.9958 35.6495C42.0343 34.8383 41.7689 34.0213 41.1493 33.4018Z" fill="black" />
                            </svg>
                        </span>
                        <span className='highlight'>Let's build your dream site.</span>
                        <span className='section-info'> Elevating businesses through innovative web design – Explore my portfolio of successful projects.</span>
                    </div>
                </div>
                <div className="card-wrapper">
                  <div className='card-container'>
                      <div className='card'>
                      {homeProjects.map(card =>
                        <div className='each-card'>
                          <div className='overlay'>
                            <div className='button-container'>
                              <span className='subtype'>{card.subtype}</span>
                              <a target="_blank" rel="noopener noreferrer" href={`${card.link}`}>
                              <button className='card-button' onClick={() => visitSiteButtonClick(card.title)}> Visit Site</button>
                              </a>
                              {/* <button onClick={() => history.push(card.page)} className='card-button'>See details</button> */}
                            </div>
                          </div>
                          <div className='info'>
                            <span className='info-type'>{card.type}</span>
                            <span className='info-title'>{card.title}</span>
                          </div>
                          <img className='thumbnail' src={`img/thumbnail/projects/${card.thumbnail}`} />
                        </div>
                      )}

                        {/* <div className='seeMore-container'>
                          <div class="grid-seeMore">
                            <div className='seeMore-each q1'>
                              <img className='thumbnail' src={`img/thumbnail/projects/sample2.jpg`} />
                            </div>
                            <div className='seeMore-each q2'>
                              <img className='thumbnail' src={`img/thumbnail/projects/sample2.jpg`} />
                            </div>
                            <div className='seeMore-each q3'>
                              <img className='thumbnail' src={`img/thumbnail/projects/sample2.jpg`} />
                            </div>
                            <div onClick={() => history.push('/projects')} className='seeMore-each q4'>
                              <div className='cover'></div>
                              <span>
                                See more
                              </span>
                              <svg height="15" viewBox="0 0 42 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M41.1493 33.4018L5.73415 1.12786C4.572 -0.0283801 2.68725 -0.0283801 1.52511 1.12786C0.362968 2.28402 0.362968 4.16001 1.52511 5.31626L34.8049 35.6465L1.52806 65.9767C0.365916 67.1329 0.365916 69.0089 1.52806 70.1651C2.6902 71.3212 4.57495 71.3212 5.73701 70.1651L41.1522 37.8911C41.7716 37.2747 42.0372 36.4576 41.9958 35.6495C42.0343 34.8383 41.7689 34.0213 41.1493 33.4018Z" fill="black" />
                              </svg>
                            </div>
                          </div>
                        </div> */}
                        
                      </div>
                  </div>
                </div>
            </section>

            {/* <section className="templates">
                <div className="wrapper">
                    <div className="flex flex-column">
                        <span className="title">Templates</span>
                        <span className="subtitle">
                            <span>See all</span>
                            <svg height="15" viewBox="0 0 42 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M41.1493 33.4018L5.73415 1.12786C4.572 -0.0283801 2.68725 -0.0283801 1.52511 1.12786C0.362968 2.28402 0.362968 4.16001 1.52511 5.31626L34.8049 35.6465L1.52806 65.9767C0.365916 67.1329 0.365916 69.0089 1.52806 70.1651C2.6902 71.3212 4.57495 71.3212 5.73701 70.1651L41.1522 37.8911C41.7716 37.2747 42.0372 36.4576 41.9958 35.6495C42.0343 34.8383 41.7689 34.0213 41.1493 33.4018Z" fill="black" />
                            </svg>
                        </span>
                    </div>
                </div>
            </section>

            <section className="ui-designs">
                <div className="wrapper">
                    <div className="flex flex-column">
                        <span className="title">UI Designs</span>
                        <span className="subtitle">
                            <span>See all</span>
                            <svg height="15" viewBox="0 0 42 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M41.1493 33.4018L5.73415 1.12786C4.572 -0.0283801 2.68725 -0.0283801 1.52511 1.12786C0.362968 2.28402 0.362968 4.16001 1.52511 5.31626L34.8049 35.6465L1.52806 65.9767C0.365916 67.1329 0.365916 69.0089 1.52806 70.1651C2.6902 71.3212 4.57495 71.3212 5.73701 70.1651L41.1522 37.8911C41.7716 37.2747 42.0372 36.4576 41.9958 35.6495C42.0343 34.8383 41.7689 34.0213 41.1493 33.4018Z" fill="black" />
                            </svg>
                        </span>
                    </div>
                </div>
            </section>

            <section className="ui-kits">
                <div className="wrapper">
                    <div className="flex flex-column">
                        <span className="title">UI Kits</span>
                        <span className="subtitle">
                            <span>See all</span>
                            <svg height="15" viewBox="0 0 42 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M41.1493 33.4018L5.73415 1.12786C4.572 -0.0283801 2.68725 -0.0283801 1.52511 1.12786C0.362968 2.28402 0.362968 4.16001 1.52511 5.31626L34.8049 35.6465L1.52806 65.9767C0.365916 67.1329 0.365916 69.0089 1.52806 70.1651C2.6902 71.3212 4.57495 71.3212 5.73701 70.1651L41.1522 37.8911C41.7716 37.2747 42.0372 36.4576 41.9958 35.6495C42.0343 34.8383 41.7689 34.0213 41.1493 33.4018Z" fill="black" />
                            </svg>
                        </span>
                    </div>
                </div>
            </section> */}

            <section className="ar section-bottom">
                <div className="wrapper">
                    <div className="flex flex-column">
                      <span className="title">Explore AR</span>
                    </div>
                </div>
                <div className="wrapper">
                    <div className="flex flex-column">
                        <span onClick={() => history.push('/ar')} className="subtitle">
                            <span>See all</span>
                            <svg height="15" viewBox="0 0 42 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M41.1493 33.4018L5.73415 1.12786C4.572 -0.0283801 2.68725 -0.0283801 1.52511 1.12786C0.362968 2.28402 0.362968 4.16001 1.52511 5.31626L34.8049 35.6465L1.52806 65.9767C0.365916 67.1329 0.365916 69.0089 1.52806 70.1651C2.6902 71.3212 4.57495 71.3212 5.73701 70.1651L41.1522 37.8911C41.7716 37.2747 42.0372 36.4576 41.9958 35.6495C42.0343 34.8383 41.7689 34.0213 41.1493 33.4018Z" fill="black" />
                            </svg>
                        </span>
                        <span className='highlight'>Augmented Reality</span>
                        <span className='section-info'>Experience the future with my augmented reality solutions, transforming websites into immersive digital experiences.</span>
                        <span className='info'>
                          To experience in Augmented Reality, 
                          <Tooltip 
                           title="‎ "
                           componentsProps={{
                            tooltip: {
                              sx: {
                                backgroundColor: "transparent",
                              }
                            }
                          }}
                           PopperProps={{
                             sx: {
                               width: 200,
                               height: 200,
                               marginTop: "10px !important",
                               backgroundImage:`url(${qrAR})`,
                             },
                           }}
                           >
                            <span className='scan-qr'> scan this QR</span>
                          </Tooltip>
                          on your handheld devices.
                        </span>
                        <span className='info-ar'>Click this <span class="material-symbols-outlined">view_in_ar</span>icon to view in AR.</span>
                    </div>
                </div>

                <div className="card-wrapper">
                  <div className='card-container'>
                      <div className='card'>
                      {homeAR.map(card =>
                        <div className='each-card'>
                          <div className='info'>
                            {/* <span className='info-type'>{card.type}</span>
                            <span className='info-title'>{card.title}</span> */}
                          </div>

                          <model-viewer 
                          src={`img/3d/${card.android}`}
                          ios-src={`img/3d/${card.ios}`}
                          poster={`img/3d/${card.poster}`}
                          alt={`${card.alt}`}
                          ar-scale={`${card.scale}`}
                          ar-placement={`${card.placement}`}
                          ar 
                          camera-controls 
                          touch-action="pan-y" 
                          xr-environment>
                          </model-viewer>

                        </div>
                      )}
                      </div>
                  </div>
                </div>
            </section>

        </div>
        </ThemeProvider>
    )
}

export default Home;