import React, { useState, useRef, useEffect } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { lightTheme, darkTheme, GlobalStyles } from '../themes.js'
import { useHistory } from "react-router-dom";
import { Tooltip } from '@mui/material';
import './styles/ar.scss'

import arLight from '../assets/img/arLight.svg'
import arDark from '../assets/img/arDark.svg'

import ARData from '../data/AR.json'

function AR(themeData) {

  useEffect(() => {
    document.title = 'Explore AR • Jins Gonzales Design';
  }, []);

    const history = useHistory();
    const qrAR = themeData.theme === 'light' ? arDark : arLight;
  
    return (
        <ThemeProvider theme={themeData.theme === 'light' ? lightTheme : darkTheme}>
        <GlobalStyles />
        <div id='ar'>

          <section className="ar section-top section-bottom">
            <div className='pagetopMargin'></div>
            <div className="wrapper">
              <div className="flex flex-column">
                <span className="title">Explore AR</span>
              </div>
            </div>
            <div className="wrapper">
                <div className="flex flex-column">
                    <span onClick={() => history.goBack()} className="subtitle goback">
                        <svg className='back' height="15" viewBox="0 0 42 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M41.1493 33.4018L5.73415 1.12786C4.572 -0.0283801 2.68725 -0.0283801 1.52511 1.12786C0.362968 2.28402 0.362968 4.16001 1.52511 5.31626L34.8049 35.6465L1.52806 65.9767C0.365916 67.1329 0.365916 69.0089 1.52806 70.1651C2.6902 71.3212 4.57495 71.3212 5.73701 70.1651L41.1522 37.8911C41.7716 37.2747 42.0372 36.4576 41.9958 35.6495C42.0343 34.8383 41.7689 34.0213 41.1493 33.4018Z" fill="black" />
                        </svg>
                        <span>Back</span>
                    </span>
                    <span className='highlight'>Augmented Reality</span>
                    <span className='section-info'>Experience the future with my augmented reality solutions, transforming websites into immersive digital experiences.</span>
                    <span className='info'>
                        To experience in Augmented Reality, 
                        <Tooltip 
                          title="‎ "
                          componentsProps={{
                          tooltip: {
                            sx: {
                              backgroundColor: "transparent",
                            }
                          }
                        }}
                          PopperProps={{
                            sx: {
                              width: 200,
                              height: 200,
                              marginTop: "10px !important",
                              backgroundImage:`url(${qrAR})`,
                            },
                          }}
                          >
                          <span className='scan-qr'> scan this QR</span>
                        </Tooltip>
                        on your handheld devices.
                      </span> 
                    <span className='info-ar'>Click this <span class="material-symbols-outlined">view_in_ar</span>icon to view in AR.</span>
                </div>
            </div>

            <div className="card-wrapper">
                  <div className='card-container'>
                      <div className='card'>
                      {ARData.map(card =>
                        <div className='each-card'>
                          <div className='info'>
                            {/* <span className='info-type'>{card.type}</span>
                            <span className='info-title'>{card.title}</span> */}
                          </div>

                          <model-viewer 
                          src={`img/3d/${card.android}`}
                          ios-src={`img/3d/${card.ios}`}
                          poster={`img/3d/${card.poster}`}
                          alt={`${card.alt}`}
                          ar-scale={`${card.scale}`}
                          ar-placement={`${card.placement}`}
                          ar 
                          camera-controls 
                          touch-action="pan-y" 
                          xr-environment>
                          </model-viewer>

                        </div>
                      )}
                      </div>
                  </div>
                </div>
          </section>

        </div>
        </ThemeProvider>
        
    )
}

export default AR;