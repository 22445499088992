import { createGlobalStyle } from 'styled-components'
import styled, { keyframes } from 'styled-components'

export const lightTheme = {
    hr: '0.5px solid rgba(0,0,0,0.09)',
    hr2: '1px solid rgba(0,0,0,0.09)',
    vl: '1px solid #F5F5F5',
    fill: '#F5F5F5',
    dash: '3px dashed white;',
    footer: '#EEEEEE !important',
    info: "#c2c2c2 !important",
    mainBg: '#F5F5F5 !important',
    mainBgReverse: '#363636 !important',
    mainTextFooter: '#696969 !important',
    mainText: '#363636 !important',
    mainTextRevert: '#F5F5F5 !important',
    mainBorder: '1px solid #696969',
    navShadow: '1px 1px 3px rgba(0, 0, 0, 0.15)',
    neuShadow: '-1px -2px 1px rgba(255, 255, 255, 1), 1px 1px 3px rgba(0, 0, 0, 0.15) !important',
    neuShadowActive: 'inset -1px -2px 1px rgba(255, 255, 255, 1), inset 1px 1px 3px rgba(0, 0, 0, 0.15) !important'
}

export const darkTheme = {
    hr: '0.5px solid rgba(255, 255, 255, 0.09)',
    hr2: '1px solid rgba(255, 255, 255, 0.09)',
    vl: '1px solid #363636',
    fill: '#363636',
    dash: '3px dashed #363636;',
    footer: '#313131 !important',
    info: "#535353 !important",
    mainBg: '#363636 !important',
    mainBgReverse: '#F5F5F5 !important',
    mainTextFooter: '#a7a7a7 !important',
    mainText: '#F5F5F5 !important',
    mainRevert: '#363636 !important',
    mainBorder: '1px solid #a7a7a7',
    navShadow: '1px 1px 3px rgba(0, 0, 0, 0.50)',
    neuShadow: '-1px -2px 1px rgba(255, 255, 255, 0.15), 1px 1px 3px rgba(0, 0, 0, 0.50) !important',
    neuShadowActive: 'inset -1px -2px 1px rgba(255, 255, 255, 0.15), inset 1px 1px 3px rgba(0, 0, 0, 0.50) !important'
}

let fill = true;

const fillAnimation = (props) => {
        return keyframes`
        from{
            fill: transparent;
        }
        to{
            fill: ${props}
        }
    `
}

const fillAnimationDelay = () => {
    if(fill === true) {
        setTimeout(function(){ fill = false; }, 3500);
        return `
        0.5s ease forwards 4.5s 
    `
    } else {
        return `
        0s ease forwards 0s 
    `
    }
}

const strokeStyle = (props) => {
    if(fill === true) {
        return props
    }
    else {
        // return `
        //     transparent
        // `
        return props
    }
}

export const GlobalStyles = createGlobalStyle`

    body {
        color: ${(props) => props.theme.mainText};
    }
    nav {
        background-color: ${(props) => props.theme.mainBg};
        box-shadow: ${(props) => props.theme.navShadow};
    }
    section {
        background-color: ${(props) => props.theme.mainBg}
    }
    section .subtitle path,span {
        fill: ${(props) => props.theme.mainText};
    }
    .logo svg path {
      fill: ${(props) => props.theme.mainBg};
    }
    .motto {
      color: ${(props) => props.theme.mainBg};
    }
    .footer {
        background-color: ${(props) => props.theme.footer};
        color: ${(props) => props.theme.mainTextFooter};
    }
    .footer a{
        color: ${(props) => props.theme.mainTextFooter};
    }
    .footer svg path {
      fill: ${(props) => props.theme.mainTextFooter};
    }
    @media screen and (max-width: 480px) {
      .soc-medLink, .site-mapLink {
        border: ${(props) => props.theme.mainBorder};
      }
    }

    hr {
        border: ${(props) => props.theme.hr}
    }


    .link-name {
        background-color: ${(props) => props.theme.mainBg};
        box-shadow : ${(props) => props.theme.neuShadow}
    }
    .active-route {
        box-shadow : ${(props) => props.theme.neuShadowActive}
    }
    .link-name:active {
        box-shadow : ${(props) => props.theme.neuShadowActive}
    }

    .neu {
        box-shadow : ${(props) => props.theme.neuShadow}
    }
    .mode:active {
        box-shadow : ${(props) => props.theme.neuShadowActive}
    }

    #hi {
        color: ${(props) => props.theme.mainBg}
    }
    .create-sv-path {
        stroke: ${props => strokeStyle(props.theme.mainBg)};

    }
    #create, #exp {
        animation: ${props => fillAnimation(props.theme.fill)} ${fillAnimationDelay} ;
    }
    .call-to-action {
      color: ${(props) => props.theme.mainBg}
    }
    .vl {
      border-left: ${(props) => props.theme.vl}
    }
    .circle1 {
      border: ${(props) => props.theme.dash}
    }
    .circle2 {
      border: ${(props) => props.theme.dash}
    }
    .circle3 {
      border: ${(props) => props.theme.dash}
    }
    .circle4 {
      border: ${(props) => props.theme.dash}
    }

    .backdrop {
        background-color: ${(props) => props.theme.mainBg}
    }
    .menu-open {
        background-color: ${(props) => props.theme.mainBg}
    }
    .menu-top-in:active, .menu-bot-in:active {
        box-shadow : ${(props) => props.theme.neuShadowActive}
    }

    .card-container .card .each-card {
      box-shadow : ${(props) => props.theme.neuShadow}
    }
    .card-container .card .overlay {
      background-color: ${(props) => props.theme.mainBg}
    }
    .card-container .card .overlay .button-container .card-button {
      background-color: ${(props) => props.theme.mainBg};
      box-shadow : ${(props) => props.theme.neuShadow}
    }
    .card-container .card .overlay .button-container .card-button:active {
      box-shadow : ${(props) => props.theme.neuShadowActive}
    }

    .seeMore-container {
      box-shadow : ${(props) => props.theme.neuShadow}
    }
    .q4 {
      background-color: ${(props) => props.theme.footer}
    }
    .q4:hover span {
      color: ${(props) => props.theme.mainBg}
    }
    .q4:hover svg path {
      fill: ${(props) => props.theme.mainBg}
    }

    .form-button {
      background-color: ${(props) => props.theme.mainBg};
      box-shadow : ${(props) => props.theme.neuShadow}
    }
    .form-button:active {
      box-shadow : ${(props) => props.theme.neuShadowActive}
    }
    input[type=text], input[type=email], select, textarea {
      background-color: ${(props) => props.theme.mainBg};
      box-shadow : ${(props) => props.theme.neuShadowActive}
    }
    .touch, .idea {
      color: ${(props) => props.theme.mainBgReverse}
    }

    .card-container-services .each-card {
      background-color: ${(props) => props.theme.mainBg};
      box-shadow : ${(props) => props.theme.neuShadow}
    }
    .subtitle-service {
      color: ${(props) => props.theme.mainTextFooter};
    }
    #services .overlay-service {
      box-shadow : ${(props) => props.theme.neuShadow}
    }
    #services .overlay-container {
      background-color: ${(props) => props.theme.mainBg};
    }
    #services .overlay-service .button-container .card-button {
      background-color: ${(props) => props.theme.mainBg};
      box-shadow : ${(props) => props.theme.neuShadow}
    }
    #services .overlay-service .button-container .card-button:active {
      box-shadow : ${(props) => props.theme.neuShadowActive}
    }

    .card-container-about .each-card {
      background-color: ${(props) => props.theme.mainBg};
      box-shadow : ${(props) => props.theme.neuShadow}
    }
    .card-container-about .hr {
      border-top: ${(props) => props.theme.hr2}
    }
    .linkedin {
      background-color: ${(props) => props.theme.mainBg};
      box-shadow : ${(props) => props.theme.neuShadow}
    }
    .linkedin:active {
      box-shadow : ${(props) => props.theme.neuShadowActive}
    }
    
    .privacy-info {
      color: ${(props) => props.theme.mainTextFooter};
    }
    strong {
      color: ${(props) => props.theme.mainText};
    }

    .terms-info {
      color: ${(props) => props.theme.mainTextFooter};
    }


    .overview .div1, .div2, .div3, .div4, .div5, .div6, .div7, .div8 {
      background-color: ${(props) => props.theme.footer};
    }
    .messenger .outer {
      fill: ${(props) => props.theme.mainBgReverse};
    }
    .messenger .middle {
      fill: ${(props) => props.theme.footer};
    }
    .analytics path {
      fill: ${(props) => props.theme.mainBgReverse};
    }
    .lightning path {
      fill: ${(props) => props.theme.mainBgReverse};
    }
    .secure path {
      fill: ${(props) => props.theme.mainBgReverse};
    }
    .mobile .screen {
      fill: ${(props) => props.theme.mainBgReverse};
    }
    .mobile .button {
      fill: ${(props) => props.theme.footer};
    }
    .tablet .screen {
      fill: ${(props) => props.theme.mainBgReverse};
    }
    .tablet .button {
      fill: ${(props) => props.theme.footer};
    }
    .desktop path, .desktop rect {
      fill: ${(props) => props.theme.mainBgReverse};
    }
    .motion path {
      fill: ${(props) => props.theme.mainBgReverse};
    }
    .theme path, .theme rect {
      fill: ${(props) => props.theme.mainBgReverse};
    }

    #pricing .each-service {
      background-color: ${(props) => props.theme.footer};
    }
    .table-cell .type {
      color: ${(props) => props.theme.mainTextFooter};
    }
    .table-cell svg path {
      fill: ${(props) => props.theme.info};
    }
    .table-cell svg path:hover {
      fill: ${(props) => props.theme.mainText};
    }
    .price-container .hr {
      border-top: ${(props) => props.theme.hr2}
    }

`