import React, { useState, useRef, useEffect } from 'react'
import './styles/contact.scss'

import styled, { ThemeProvider } from 'styled-components'
import { lightTheme, darkTheme, GlobalStyles } from '../themes.js'
import ReactGA from "react-ga4";

// Initialize ReactGA with your Measurement ID
ReactGA.initialize('G-WER1Z42GL5');

function Contact(themeData) {

  useEffect(() => {
    document.title = 'Contact • Jins Gonzales Design';
  }, []);

  const contactButtonClick = () => {
    console.log('REACT-GA4 CONTACT BUTTON CLICKED')
    ReactGA.event({
      category: "contact",
      action: "click on contact",
      label: "contact",
    })
  };
  
    return (
        <ThemeProvider theme={themeData.theme === 'light' ? lightTheme : darkTheme}>
        <GlobalStyles />
        <div id='contact'>

          <section className="contact">
            <div className='pagetopMargin'></div>

            <div className="wrapper form">
              <div className='left'>
                <span className='idea'>
                  Got ideas in <span className='title'>mind?</span> 
                </span>
                <a href="mailto:jinsgonzales.design@gmail.com?subject=INQUIRY" onClick={() => contactButtonClick()}>
                <button className='form-button' type="submit">Get in Touch</button>
                </a>
              </div>
            </div>

          </section>

        </div>
        </ThemeProvider>
        
    )
}

export default Contact;