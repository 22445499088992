import React, { useState, useRef, useEffect } from 'react'

import styled, { ThemeProvider } from 'styled-components'
import { lightTheme, darkTheme, GlobalStyles } from '../themes.js'
import { useHistory } from "react-router-dom";
import ReactGA from "react-ga4";
import homeProjects from '../data/projects.json'

// Initialize ReactGA with your Measurement ID
ReactGA.initialize('G-WER1Z42GL5');

function Projects(themeData) {

  useEffect(() => {
    document.title = 'Websites • Jins Gonzales Design';
  }, []);

    const history = useHistory();

    // track projects button click google analytics
    const visitSiteButtonClick = (card) => {
      console.log('REACT-GA4 VIEW PROJECT BUTTON CLICKED')
      // console.log('JSON DATA', card)
      ReactGA.event({
        category: card,
        action: "click on projects",
        label: card,
      })
    };
  
    return (
        <ThemeProvider theme={themeData.theme === 'light' ? lightTheme : darkTheme}>
        <GlobalStyles />
        <div id='projects'>

          <section className="projects section-top section-bottom">
            <div className='pagetopMargin'></div>
            <div className="wrapper">
              <div className="flex flex-column">
                <span className="title">Websites</span>
              </div>
            </div>
            <div className="wrapper">
                <div className="flex flex-column">
                    <span onClick={() => history.goBack()} className="subtitle goback">
                        <svg className='back' height="15" viewBox="0 0 42 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M41.1493 33.4018L5.73415 1.12786C4.572 -0.0283801 2.68725 -0.0283801 1.52511 1.12786C0.362968 2.28402 0.362968 4.16001 1.52511 5.31626L34.8049 35.6465L1.52806 65.9767C0.365916 67.1329 0.365916 69.0089 1.52806 70.1651C2.6902 71.3212 4.57495 71.3212 5.73701 70.1651L41.1522 37.8911C41.7716 37.2747 42.0372 36.4576 41.9958 35.6495C42.0343 34.8383 41.7689 34.0213 41.1493 33.4018Z" fill="black" />
                        </svg>
                        <span>Back</span>
                    </span>
                    {/* <span className='highlight'>Let's build your dream site.</span> */}
                    <span className='section-info'> Elevating businesses through innovative web design – Explore my portfolio of successful projects.</span>
                </div>
            </div>
            <div className="card-wrapper">
              <div className='card-container'>
                  <div className='card'>
                  {homeProjects.map(card =>
                    <div className='each-card'>
                      <div className='overlay'>
                        <div className='button-container'>
                          <span className='subtype'>{card.subtype}</span>
                          <a target="_blank" rel="noopener noreferrer" href={`${card.link}`}>
                          <button className='card-button' onClick={() => visitSiteButtonClick(card.title)}> Visit site</button>
                          </a>
                          {/* <button onClick={() => history.push(card.page)} className='card-button'>See details</button> */}
                        </div>
                      </div>
                      <div className='info'>
                        <span className='info-type'>{card.type}</span>
                        <span className='info-title'>{card.title}</span>
                      </div>
                      <img className='thumbnail' src={`img/thumbnail/projects/${card.thumbnail}`} />
                    </div>
                  )}
                    
                  </div>
              </div>
            </div>
          </section>

        </div>
        </ThemeProvider>
        
    )
}

export default Projects;