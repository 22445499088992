import React, { useState, useRef, useEffect } from 'react'

import styled, { ThemeProvider } from 'styled-components'
import { lightTheme, darkTheme, GlobalStyles } from '../themes.js'
import './styles/services.scss'

function Services(themeData) {

  useEffect(() => {
    document.title = 'Services • Jins Gonzales Design';
  }, []);
  
    return (
        <ThemeProvider theme={themeData.theme === 'light' ? lightTheme : darkTheme}>
        <GlobalStyles />
        <div id='services'>

          <section className="services section-top section-bottom">
            <div className='pagetopMargin'></div>
            <div className="wrapper">
                <div className="flex flex-column">
                    <span className="title">Services</span>
                </div>
            </div>

            <div className='wrapper'>
              <div className='type-container'>
                <span className='type'>Static Website</span>
                {/* <br/> */}
                <span className='description'>Website with fixed content, no server-side processing or dynamic features.</span>
              </div>
            </div>

            <div className='card-wrapper'>
              <div className='card-container-services'>
                <div className='each-card basic'>
                  <div className='overlay-service'>
                    <div className='overlay-container'>
                      <div className='button-container'>
                        <a target="_blank" rel="noopener noreferrer" href="mailto:jinsgonzales.design@gmail.com?subject=BASIC-WEBSITE_QUOTE_REQUEST">
                        <button className='card-button'> Request for Quote</button>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className='card-content'>
                    <span className='title-service'>Basic</span>
                    <span className='subtitle-service'>For Personal or Business Starters</span>
                    <div className='content-service'>
                      <div className='content-service-wrapper'>
                      <div><span class="material-symbols-outlined">check_circle</span>Fixed Layout</div>
                      <div><span class="material-symbols-outlined">check_circle</span>Up to 5 Pages *</div>
                      <div><span class="material-symbols-outlined">check_circle</span>404 Error Page</div>
                      <div><span class="material-symbols-outlined">check_circle</span>3 Sets of Revision</div>
                      <div><span class="material-symbols-outlined">check_circle</span>Responsive Design</div>
                      <div><span class="material-symbols-outlined">check_circle</span>Secured with SSL</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='each-card standard'>
                  <div className='overlay-service'>
                    <div className='overlay-container'>
                      <div className='button-container'>
                        <a target="_blank" rel="noopener noreferrer" href="mailto:jinsgonzales.design@gmail.com?subject=STANDARD-WEBSITE_QUOTE_REQUEST">
                        <button className='card-button'> Request for Quote</button>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className='card-content'>
                    <span className='title-service-standard'>Standard</span>
                    <span className='subtitle-service-standard'>For Medium-Sized Businesses</span>
                    <div className='content-service'>
                      <div className='content-service-wrapper'>
                      <div><span class="material-symbols-outlined">check_circle</span>Custom Layout</div>
                      <div><span class="material-symbols-outlined">check_circle</span>Up to 8 Pages *</div>
                      <div><span class="material-symbols-outlined">check_circle</span>404 Error Page</div>
                      <div><span class="material-symbols-outlined">check_circle</span>5 Sets of Revision</div>
                      <div><span class="material-symbols-outlined">check_circle</span>Responsive Design</div>
                      <div><span class="material-symbols-outlined">check_circle</span>Secured with SSL</div>
                      <div><span class="material-symbols-outlined">check_circle</span>SEO Friendly</div>
                      <div><span class="material-symbols-outlined">check_circle</span>Messenger Integration</div>
                      <div><span class="material-symbols-outlined">check_circle</span>Google Analytics Integration</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='each-card premium'>
                  <div className='overlay-service'>
                    <div className='overlay-container'>
                      <div className='button-container'>
                        <a target="_blank" rel="noopener noreferrer" href="mailto:jinsgonzales.design@gmail.com?subject=PREMIUM-WEBSITE_QUOTE_REQUEST">
                        <button className='card-button'> Request for Quote</button>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className='card-content'>
                    <span className='title-service'>Premium</span>
                    <span className='subtitle-service'>Top-Tier Web Experience</span>
                    <div className='content-service'>
                      <div className='content-service-wrapper'>
                      <div><span class="material-symbols-outlined">check_circle</span>Custom Layout</div>
                      <div><span class="material-symbols-outlined">check_circle</span>Up to 12 Pages *</div>
                      <div><span class="material-symbols-outlined">check_circle</span>404 Error Page</div>
                      <div><span class="material-symbols-outlined">check_circle</span>8 Sets of Revision</div>
                      <div><span class="material-symbols-outlined">check_circle</span>Responsive Design</div>
                      <div><span class="material-symbols-outlined">check_circle</span>Secured with SSL</div>
                      <div><span class="material-symbols-outlined">check_circle</span>SEO Optimized</div>
                      <div><span class="material-symbols-outlined">check_circle</span>Messenger Integration</div>
                      <div><span class="material-symbols-outlined">check_circle</span>Google Analytics Integration</div>
                      <div><span class="material-symbols-outlined">check_circle</span>Light | Dark Mode Theme</div>
                      <div><span class="material-symbols-outlined">check_circle</span>Custom Page Animations</div>
                      <div><span class="material-symbols-outlined">check_circle</span>Custom Page Translations</div>
                      </div>
                    </div>
                  </div>  
                </div>
              </div>
            </div>
            
          </section>

        </div>
        </ThemeProvider>
        
    )
}

export default Services;