import React, { useState, useRef, useEffect } from 'react'

import styled, { ThemeProvider } from 'styled-components'
import { lightTheme, darkTheme, GlobalStyles } from '../themes.js'
import { useHistory } from "react-router-dom";
import './styles/privacy.scss'

function Privacy(themeData) {

  useEffect(() => {
    document.title = 'Privacy Policy • Jins Gonzales Design';
  }, []);

    const history = useHistory();
  
    return (
        <ThemeProvider theme={themeData.theme === 'light' ? lightTheme : darkTheme}>
        <GlobalStyles />
        <div id='privacy'>

          <section className="privacy section-top section-bottom">
            <div className='pagetopMargin'></div>
            <div className="wrapper">
                <div className="flex flex-column">
                    <span className="title">Privacy Policy</span>
                </div>
            </div>
            <div className="wrapper">
              <div className='privacy-content'>
                <div className='privacy-title'>Introduction</div>
                <br/>
                <div className='privacy-info'>Welcome to Jins Gonzales Design (referred to as "we," "us," or "our"). At Jins Gonzales Design, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your personal information when you visit our website or engage with our services.</div>
                <br/>
                <div className='privacy-info'>By accessing or using our website or services, you consent to the practices described in this Privacy Policy. Please take a moment to read this Privacy Policy carefully.</div>
                <br/>
                <div className='privacy-title'>Information We Collect</div>
                <br/>
                <div className='privacy-info'>We may collect the following types of personal information when you visit our website or interact with our services:</div>
                <br/>
                <div className='terms-info'>
                  <ul>
                    <li><strong>Personal Information:</strong> This may include your name, email address, phone number, and any other information you provide to us voluntarily.</li>
                    <li><strong>Log Data:</strong>  We collect information that your browser sends when you visit our website, such as your IP address, browser type, browser version, the pages you visit, and the time and date of your visit.</li>
                  </ul> 
                </div>
                <br/>
                <div className='privacy-title'>How We Use Your Information</div>
                <br/>
                <div className='privacy-info'>We may use your personal information for the following purposes:</div>
                <br/>
                <div className='terms-info'>
                  <ul>
                    <li><strong>To Provide Services:</strong>  We use your personal information to provide web design and development services, communicate with you about your projects, and respond to your inquiries.</li>
                    <li><strong>To Improve Our Services:</strong>  We analyze usage data to enhance and improve our website and services, including the user experience.</li>
                    <li><strong>To Communicate:</strong>  We may send you promotional materials, newsletters, updates, or other information about our services, but you can opt out of receiving such communications at any time.</li>
                  </ul> 
                </div>
                <br/>
                <div className='privacy-title'>Disclosure of Your Information</div>
                <br/>
                <div className='privacy-info'>We do not sell, trade, or rent your personal information to third parties. However, we may share your information with third-party service providers who assist us in operating our website and providing our services. These service providers are required to maintain the confidentiality of your information.</div>
                <br/>
                <div className='privacy-title'>Cookies and Tracking Technologies</div>
                <br/>
                <div className='privacy-info'>Our website may use cookies and similar tracking technologies to enhance your experience. You can modify your browser settings to reject cookies or notify you when cookies are being used.</div>
                <br/>
                <div className='privacy-title'>Security</div>
                <br/>
                <div className='privacy-info'>We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, please be aware that no data transmission over the internet or electronic storage method is entirely secure.</div>
                <br/>
                <div className='privacy-title'>Your Choices</div>
                <br/>
                <div className='privacy-info'>You have the right to access, update, or delete your personal information. If you have any questions about your personal information or wish to exercise your rights, please contact us.</div>
                <br/>
                <div className='privacy-title'>Changes to this Privacy Policy</div>
                <br/>
                <div className='privacy-info'>We reserve the right to update or modify this Privacy Policy at any time. We will indicate the date of the most recent revisions at the beginning of this page. We encourage you to review this Privacy Policy periodically.</div>
                <br/>
                <div className='privacy-info'>Thank you for choosing Jins Gonzales Design. Your privacy is important to us.</div>
                
              </div>
            </div>
          </section>

        </div>
        </ThemeProvider>
        
    )
}

export default Privacy;