import React, { useState, useRef, useEffect } from 'react'
import { AnimatePresence, motion } from 'framer-motion';
import styled, { ThemeProvider } from 'styled-components'
import { lightTheme, darkTheme, GlobalStyles } from '../themes.js'
import './styles/about.scss'
import ReactGA from "react-ga4";
import { gsap, power2 } from 'gsap'

import jins from '../assets/img/jins.jpg'

// Initialize ReactGA with your Measurement ID
ReactGA.initialize('G-WER1Z42GL5');

function About(themeData) {

  useEffect(() => {
    document.title = 'About • Jins Gonzales Design';
  }, []);

  const linkedinButtonClick = () => {
    console.log('REACT-GA4 LINKEDIN BUTTON CLICKED')
    ReactGA.event({
      category: "linkedin",
      action: "click on social",
      label: "linkedin",
    })
  };
  // useEffect(() => {
  //   linkedinButtonClick();
  // }, []);
  
    return (
        <ThemeProvider theme={themeData.theme === 'light' ? lightTheme : darkTheme}>
        <GlobalStyles />
        <div id='about'>

          <section className="about section-top section-bottom">
            <div className='pagetopMargin'></div>
            <div className="wrapper">
                <div className="flex flex-column">
                    <span className="title">About</span>
                </div>
            </div>

            <div className='card-wrapper'>
              <div className='card-container-about'>
                <div className='each-card info'>
                  <div className='img-container'></div>
                  <img className='thumbnail' src={jins} />
                  <div className='info-details'>
                    <div className='me'>Hi, I'm Jins!</div>
                    <span>I don't create websites. I create web experiences.</span>
                    <div className='hr'></div>
                    <span>I help business product design and development to create powerful, valuable, and profitable user experiences.</span>
                    <div>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/jinstant/" onClick={() => linkedinButtonClick()}>
                    <button className='linkedin'>LinkedIn Profile</button>
                    </a>
                    </div>
                  </div>
                </div>
                <div className='each-card overview'>
                <div class="parent">
                  <div class="each-div div1">
                    <div className='overlay'>
                      <div className='overlay-info'>
                        <span className='overlay-title'>Messenger Integration</span>
                        <span className='overlay-body'>Real-time chat and messaging functionality to enhance user interaction and support.</span>
                      </div>
                    </div>
                    <div className='icons-container'>

                      <motion.div
                      animate={{ 
                        rotate: 360,
                        scale: [1, 1, 3.5, 3.5, 1, 1],
                      }}
                       transition={{ type: 'spring', stiffness: 100, damping: 9, duration: 1.5}}
                      >
                      <svg className='messenger' width="364" height="130" viewBox="0 0 364 364" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className='outer' d="M182 0.200195C79.6002 0.200195 0.200195 75.2002 0.200195 176.6C0.200195 229.6 21.9002 275.4 57.2002 307C58.692 308.307 59.9041 309.902 60.7634 311.689C61.6226 313.476 62.1114 315.419 62.2002 317.4L63.2002 349.7C63.2808 352.078 63.9415 354.401 65.1246 356.465C66.3078 358.529 67.9776 360.273 69.9887 361.545C71.9998 362.817 74.2912 363.578 76.6635 363.762C79.0359 363.946 81.4172 363.547 83.6002 362.6L119.6 346.6C122.686 345.234 126.152 344.986 129.4 345.9C146.54 350.585 164.232 352.94 182 352.9C284.4 352.9 363.8 277.9 363.8 176.6C363.8 75.3002 284.4 0.200195 182 0.200195Z" fill="white"/>
                        <path className='middle' d="M72.7999 228.1L126.2 143.4C128.209 140.205 130.86 137.46 133.984 135.341C137.108 133.222 140.637 131.773 144.349 131.087C148.061 130.401 151.875 130.493 155.55 131.355C159.225 132.218 162.681 133.834 165.7 136.1L208.2 168C210.086 169.381 212.362 170.126 214.7 170.126C217.037 170.126 219.314 169.381 221.2 168L278.7 124.4C286.3 118.6 296.3 127.8 291.2 135.9L237.8 220.6C235.79 223.781 233.144 226.512 230.028 228.622C226.913 230.731 223.395 232.174 219.695 232.86C215.995 233.545 212.194 233.458 208.529 232.605C204.865 231.751 201.416 230.15 198.4 227.9L155.9 196C153.996 194.572 151.68 193.8 149.3 193.8C146.92 193.8 144.604 194.572 142.7 196L85.2999 239.6C77.6999 245.4 67.6999 236.2 72.7999 228.1Z" fill="#313131"/>
                      </svg>
                      </motion.div>

                    </div>
                  </div>
                  <div class="each-div div2">
                    <div className='overlay'>
                      <div className='overlay-info'>
                        <span className='overlay-title'>Google Analytics Integration</span>
                        <span className='overlay-body'>Comprehensive web data analysis tool for tracking and optimizing website performance.</span>
                      </div>
                    </div>
                    <div className='icons-container'>
                    
                      <motion.div
                      initial={{
                        y: -200}
                      }
                      animate={{ 
                        y: 0
                      }}
                       transition={{ type: 'spring', stiffness: 100, duration: 3 }}
                      
                      >
                      <svg className='analytics' width="800" height="400" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M175 708.333C221.024 708.333 258.334 671.024 258.334 625C258.334 578.976 221.024 541.667 175 541.667C128.977 541.667 91.667 578.976 91.667 625C91.667 671.024 128.977 708.333 175 708.333Z" fill="white"/>
                      </svg>
                      </motion.div>
                      <motion.div
                      initial={{
                        y: -200}
                      }
                      animate={{ 
                        y: 0
                      }}
                        transition={{ type: 'spring', stiffness: 100, duration: 2.5, delay: .5 }}
                                            
                      >
                      <svg className='analytics' width="800" height="400" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M399.999 708.333C446.023 708.333 483.333 671.023 483.333 625V358.333C483.333 312.31 446.023 275 399.999 275C353.976 275 316.666 312.31 316.666 358.333V625C316.666 671.023 353.976 708.333 399.999 708.333Z" fill="white"/>
                      </svg>
                      </motion.div>
                      <motion.div
                      initial={{
                        y: -200}
                      }
                      animate={{ 
                        y: 0
                      }}
                        transition={{ type: 'spring', stiffness: 100, duration: 2, delay: 1 }}
                      
                      >
                      <svg className='analytics' width="800" height="400" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M624.999 708.333C671.023 708.333 708.333 671.023 708.333 625V175C708.333 128.977 671.023 91.6665 624.999 91.6665C578.976 91.6665 541.666 128.977 541.666 175V625C541.666 671.023 578.976 708.333 624.999 708.333Z" fill="white"/>
                      </svg>
                      </motion.div>
                      
                    </div>
                  </div>
                  <div class="each-div div3">
                    <div className='overlay'>
                      <div className='overlay-info'>
                        <span className='overlay-title'>Fast</span>
                        <span className='overlay-body'>Optimized for quick loading, ensuring a seamless and efficient user experience.</span>
                      </div>
                    </div>
                    <div className='icons-container'>
                    
                      <motion.div
                      initial={{
                        x: -200,
                        y: -200,
                        scale: 5,
                        rotate: 45
                      }}
                      animate={{ 
                        x: 0,
                        y: 0,
                        scale: 1,
                        rotate: 0
                      }}
                       transition={{ type: 'spring', stiffness: 100, duration: 3 }}
                      
                      >
                      <svg className='lightning' width="401" height="150" viewBox="0 0 401 668" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M400 325.333C398.751 320.81 396.562 316.602 393.575 312.983C390.588 309.364 386.871 306.417 382.666 304.333L269.666 248.333L300 38.6667C300.997 31.4003 299.57 24.0078 295.94 17.6348C292.309 11.2618 286.678 6.26411 279.919 3.41619C273.16 0.568283 265.65 0.0291633 258.554 1.88237C251.457 3.73557 245.17 7.87764 240.666 13.6667L7.33314 313.667C4.22291 317.74 2.09368 322.475 1.11089 327.504C0.128101 332.534 0.318226 337.723 1.66649 342.667C2.99054 347.658 5.45629 352.274 8.86926 356.15C12.2822 360.026 16.549 363.055 21.3331 365L163.666 422L133.333 629.333C132.302 636.775 133.815 644.347 137.627 650.821C141.439 657.295 147.326 662.291 154.333 665C158.239 666.62 162.438 667.414 166.666 667.333C172.157 667.305 177.555 665.921 182.382 663.304C187.208 660.687 191.314 656.919 194.333 652.333L394.333 352.333C397.15 348.498 399.113 344.104 400.091 339.447C401.068 334.79 401.037 329.977 400 325.333Z" fill="black"/>
                      </svg>
                      </motion.div>
                    </div>
                  </div>
                  <div class="each-div div4">
                    <div className='overlay'>
                      <div className='overlay-info'>
                        <span className='overlay-title'>SSL Secured</span>
                        <span className='overlay-body'>SSL-secured for encrypted data transmission, ensuring a safe and trustworthy website.</span>
                      </div>
                    </div>
                    <div className='icons-container'>
                    
                      <motion.div
                      initial={{
                        scale: 5
                      }}
                      animate={{ 
                        scale: 1
                      }}
                       transition={{ type: 'spring', stiffness: 100, damping: 9, duration: 3 }}
                      
                      >
                      <svg className='secure' width="594" height="220" viewBox="0 0 594 667" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M545.333 61.6668L306 0.666793C303.355 0.185288 300.645 0.185288 298 0.666793C295.79 0.33325 293.543 0.33325 291.333 0.666793L50.6665 61.6668C35.407 65.4761 21.9855 74.5578 12.7748 87.3064C3.56403 100.055 -0.842829 115.65 0.333202 131.333L14.3332 315C19.9402 387.858 45.3981 457.788 87.9433 517.198C130.489 576.609 188.495 623.23 255.667 652L285 664.667C288.697 666.207 292.662 667 296.667 667C300.671 667 304.636 666.207 308.333 664.667L337.667 652C404.838 623.23 462.845 576.609 505.39 517.198C547.935 457.788 573.393 387.858 579 315L593 131.333C594.314 116.004 590.285 100.693 581.597 87.9949C572.909 75.2973 560.097 65.9954 545.333 61.6668ZM421.667 257.667L288.333 391C285.234 394.124 281.548 396.604 277.486 398.296C273.424 399.989 269.067 400.86 264.667 400.86C260.266 400.86 255.909 399.989 251.847 398.296C247.785 396.604 244.099 394.124 241 391L174.333 324.333C168.056 318.057 164.53 309.543 164.53 300.667C164.53 291.79 168.056 283.277 174.333 277C180.61 270.723 189.123 267.197 198 267.197C206.877 267.197 215.39 270.723 221.667 277L264.667 320.333L374.333 210.333C380.61 204.057 389.123 200.53 398 200.53C406.877 200.53 415.39 204.057 421.667 210.333C427.943 216.61 431.47 225.123 431.47 234C431.47 242.877 427.943 251.39 421.667 257.667Z" fill="#231F20"/>
                      </svg>

                      </motion.div>
                    </div>
                  </div>
                  <div class="each-div div5">
                    <div className='overlay'>
                      <div className='overlay-info'>
                        <span className='overlay-title'>Responsive</span>
                        <span className='overlay-body'>Adapts to various devices for an optimal user experience on all screens.</span>
                      </div>
                    </div>
                    <div className='icons-container'>
                    
                      <motion.div
                      initial={{
                        x: -200}
                      }
                      animate={{ 
                        x: 0
                      }}
                        transition={{ type: 'spring', stiffness: 100, duration: 2, delay: 1 }}
                      
                      >
                      <svg className='mobile' width="1482" height="600" viewBox="0 0 1482 697" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect className='screen' x="28" y="348" width="204.167" height="319" rx="50" fill="white"/>
                        <rect className='button' x="111.166" y="599" width="37" height="37" rx="18.5" fill="#D9D9D9"/>
                      </svg>

                      </motion.div>
                      <motion.div
                      initial={{
                        x: -200}
                      }
                      animate={{ 
                        x: 0
                      }}
                        transition={{ type: 'spring', stiffness: 100, duration: 2.5, delay: .5 }}
                       
                      >
                      <svg className='tablet' width="1482" height="600" viewBox="0 0 1482 697" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect className='screen' x="271.987" y="281" width="484.013" height="386" rx="50" fill="white"/>
                        <rect className='button'x="479" y="598" width="70" height="37" rx="18.5" fill="#D9D9D9"/>
                      </svg>

                      </motion.div>
                      <motion.div
                      initial={{
                        x: -200}
                      }
                      animate={{ 
                        x: 0
                      }}
                       transition={{ type: 'spring', stiffness: 100, duration: 3 }}
                      
                      >
                      <svg className='desktop' width="1482" height="600" viewBox="0 0 1482 697" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect className='screen' x="796" y="31" width="658" height="499" rx="100" fill="white"/>
                        <path className='stand' d="M1250.27 567H998.834C983.065 614.21 1017.65 667 1071.34 667H1177.76C1231.46 667 1266.04 614.21 1250.27 567Z" fill="white"/>
                      </svg>

                      </motion.div>
                    </div>
                  </div>
                  <div class="each-div div6">
                    <div className='overlay'>
                      <div className='overlay-info'>
                        <span className='overlay-title'>Animations</span>
                        <span className='overlay-body'>Engaging animations enhance user experience and bring content to life.</span>
                      </div>
                    </div>
                    <div className='icons-container'>
                    
                      <motion.div
                      initial={{
                        x: -200,
                        y: 200
                      }}
                      animate={{ 
                        x: 0,
                        y: 0
                      }}
                       transition={{ type: 'spring', stiffness: 100, duration: 3 }}
                      
                      >
                      <svg className='motion' width="800" height="350" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M484 44.4443C537.241 44.4445 589.287 60.2239 633.564 89.7893C677.841 119.355 712.363 161.379 732.767 210.555C753.172 259.73 758.544 313.85 748.206 366.077C737.868 418.305 712.282 466.296 674.682 503.99C637.082 541.683 589.154 567.387 536.952 577.854C484.75 588.322 430.618 583.083 381.392 562.8C332.166 542.517 290.056 508.1 260.381 463.896C230.707 419.692 214.799 367.685 214.667 314.444C214.579 279.019 221.481 243.924 234.977 211.17C248.473 178.416 268.298 148.646 293.317 123.566C318.335 98.4852 348.056 78.5866 380.777 65.0097C413.497 51.4328 448.575 44.4442 484 44.4443Z" fill="black"/>
                      </svg>

                      </motion.div>
                      <motion.div
                      initial={{
                        opacity: 0
                      }}
                      animate={{ 
                        opacity: 1
                      }}
                       transition={{ type: 'spring', stiffness: 100, duration: 3, delay: 1 }}
                      
                      >
                      <svg className='motion' width="800" height="350" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M170.666 322.889C134.245 363.178 114.561 415.842 115.628 470.143C116.695 524.445 138.433 576.295 176.41 615.122C214.387 653.949 265.743 676.829 320.007 679.098C374.272 681.367 427.359 662.854 468.444 627.333C389.599 623.392 315.143 589.854 259.943 533.418C204.743 476.982 172.862 401.802 170.666 322.889Z" fill="black"/>
                      </svg>

                      </motion.div>
                      <motion.div
                      initial={{
                        opacity: 0
                      }}
                      animate={{ 
                        opacity: 1
                      }}
                       transition={{ type: 'spring', stiffness: 100, duration: 3, delay: 1.5 }}
                      
                      >
                      <svg className='motion' width="800" height="350" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M77.7783 528.222C57.0493 556.383 47.403 591.185 50.6767 625.999C53.9503 660.813 69.9152 693.207 95.5304 717.01C121.146 740.813 154.622 754.363 189.582 755.078C224.542 755.794 258.544 743.624 285.112 720.889C235.581 712.365 189.602 689.609 152.785 655.397C115.969 621.185 89.9061 576.996 77.7783 528.222Z" fill="black"/>
                      </svg>

                      </motion.div>
                    </div>
                  </div>
                  <div class="each-div div7">
                    <div className='overlay'>
                      <div className='overlay-info'>
                        <span className='overlay-title'>Themes</span>
                        <span className='overlay-body'>Switch between dark and light themes for personalized viewing preferences.</span>
                      </div>
                    </div>
                    <div className='icons-container'>
                    
                      <motion.div
                      initial={{
                        
                      }}
                      animate={{ 
                        rotate: 360
                      }}
                       transition={{ type: 'spring', stiffness: 100, duration: 3 }}
                      
                      >
                      <svg className='theme' width="800" height="330" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_433_92)">
                        <path d="M233.333 400C233.333 444.203 250.892 486.595 282.149 517.851C313.405 549.107 355.797 566.667 400 566.667V233.333C355.797 233.333 313.405 250.893 282.149 282.149C250.892 313.405 233.333 355.797 233.333 400Z" fill="black"/>
                        <path d="M400 33.3334C327.48 33.3334 256.589 54.838 196.291 95.1279C135.993 135.418 88.9961 192.683 61.244 259.683C33.4919 326.682 26.2307 400.407 40.3786 471.533C54.5265 542.66 89.4481 607.993 140.727 659.273C192.007 710.552 257.34 745.473 328.467 759.621C399.593 773.769 473.318 766.508 540.317 738.756C607.317 711.004 664.582 664.007 704.872 603.709C745.162 543.411 766.667 472.52 766.667 400C766.887 351.787 757.554 304.008 739.205 259.422C720.857 214.836 693.856 174.328 659.764 140.236C625.672 106.143 585.163 79.1434 540.578 60.7948C495.992 42.4462 448.213 33.1128 400 33.3334ZM99.9998 400C100.437 320.57 132.185 244.518 188.351 188.352C244.518 132.185 320.57 100.438 400 100V233.333C444.203 233.333 486.595 250.893 517.851 282.149C549.107 313.405 566.667 355.797 566.667 400C566.667 444.203 549.107 486.595 517.851 517.851C486.595 549.107 444.203 566.667 400 566.667V700C320.57 699.562 244.518 667.815 188.351 611.648C132.185 555.482 100.437 479.43 99.9998 400Z" fill="black"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_433_92">
                        <rect width="800" height="800" fill="white"/>
                        </clipPath>
                        </defs>
                      </svg>

                      </motion.div>
                    </div>
                  </div>
                  <div class="each-div div8">
                    <span>Features</span>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

        </div>
        </ThemeProvider>
        
    )
}

export default About;