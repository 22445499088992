import './App.css';
import Nav from './navigation/nav'
import React, { useEffect } from 'react'

import ReactGA from "react-ga4";

// Initialize ReactGA with your Measurement ID
ReactGA.initialize('G-WER1Z42GL5');

function App() {

  useEffect(() => {
    ReactGA.send({
      hitType: "Pageview",
      page: window.location.pathname + window.location.search,
      title: "Home Page",
    })
  }, []);
  
  return (
    <div>
      <Nav />
    </div>
  );
}

export default App;
