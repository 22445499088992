import React, { useState, useEffect, useRef } from 'react'
import './nav.scss'
import { BrowserRouter as Router, Switch, Route, Link, NavLink } from 'react-router-dom'
import styled, { ThemeProvider } from 'styled-components'
import { lightTheme, darkTheme, GlobalStyles } from '../themes.js'
import logo from '../assets/img/jinsdesign.svg'
import logoGray from '../assets/img/jinsdesign-gray.svg'
import ReactGA from "react-ga4";
import ScrollToTop from 'react-router-scroll-top'

// import routing pages below
import Home from '../pages/home'
import Services from '../pages/services'
import About from '../pages/about'
import Contact from '../pages/contact'
import Terms from '../pages/terms'
import Privacy from '../pages/privacy'
import Pricing from '../pages/pricing'

import PageNotFound from '../pages/404'

import Projects from '../pages/projects'
import AR from '../pages/ar'


const MenuSyle = styled.div`

`
// Initialize ReactGA with your Measurement ID
ReactGA.initialize('G-WER1Z42GL5');

function Nav() {

    // track button click google analytics
    const themeButtonClick = () => {
      console.log('REACT-GA4 THEME BUTTON CLICKED')
      ReactGA.event({
        category: "change theme",
        action: "click on navigation",
        label: "change theme",
      })
    };
    const menuButtonClick = () => {
      console.log('REACT-GA4 MENU BUTTON CLICKED')
      ReactGA.event({
        category: "toggle nav",
        action: "click on navigation",
        label: "toggle nav",
      })
    };
    const facebookButtonClick = () => {
      console.log('REACT-GA4 FACEBOOK BUTTON CLICKED')
      ReactGA.event({
        category: "facebook",
        action: "click on social",
        label: "facebook",
      })
    };
    const instagramButtonClick = () => {
      console.log('REACT-GA4 INSTAGRAM BUTTON CLICKED')
      ReactGA.event({
        category: "instagram",
        action: "click on social",
        label: "instagram",
      })
    };
    // useEffect(() => {
    //   themeButtonClick();
    //   menuButtonClick();
    //   facebookButtonClick();
    //   instagramButtonClick();
    // }, []);

    // theme configuartion with local storage
    const [theme, setTheme] = useState("light");
    const setMode = mode => {
        window.localStorage.setItem('theme', mode);
        setTheme(mode);
    }
    const themeToggler = () => {
        theme === 'light' ? setMode('dark') : setMode('light');
    }
    useEffect(() => {
        const localTheme = window.localStorage.getItem('theme')
        localTheme ? setTheme(localTheme) : setMode('light')
    }, [])


    const [menu, setMenu] = useState(false);
    const menuToggler = () => {
        menu === false ? setMenu(true) : setMenu(false)
    }


    return (
        <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
        <GlobalStyles />
        <Router>
        <ScrollToTop>
            <nav>
                <div className="wrapper">
                    {/* <div className="menu">
                        <div className="mode neu" onClick={() => menuToggler()}>
                            <svg 
                            height="20" 
                            viewBox="0 0 33 33" 
                            fill="none" 
                            xmlns="http://www.w3.org/2000/svg">
                                <path d="M33 23.8333H0V27.5H33V23.8333Z" fill="black"/>
                                <path d="M33 14.6667H0V18.3334H33V14.6667Z" fill="black"/>
                                <path d="M33 5.5H0V9.16669H33V5.5Z" fill="black"/>
                            </svg>
                        </div>
                    </div> */}
                    <div className="nav-left">
                        <div className='logo'>
                          <a href='https://www.design.jinsgonzales.com'>
                            <img src={logo} ></img>
                          </a>
                        </div>
                        <div className="main-links">
                        <ul className='nav-links'>
                            <NavLink className='link-name' activeClassName="active-route" exact to='/'><li>Home</li></NavLink>
                            <NavLink className='link-name' activeClassName="active-route" to='/services'><li>Services</li></NavLink>
                            <NavLink className='link-name' activeClassName="active-route" to='/about'><li>About</li></NavLink>
                        </ul>
                        </div>
                    </div>

                    <div className='nav-rightMobile'>
                      <div className="nav-right">
                          <div className="main-link-contact">
                              <ul className='nav-links'>
                                  <NavLink className='link-name' activeClassName="active-route" exact to='/contact'><li>Contact</li></NavLink>
                              </ul>
                          </div>
                          <div className="mode neu" onClick={() => {themeToggler();themeButtonClick()}}>
                              <svg 
                              height="20" 
                              viewBox="0 0 30 30" 
                              fill="none" 
                              xmlns="http://www.w3.org/2000/svg">
                                  <path d="M15 0C6.70951 0 0 6.70881 0 15C0 23.2905 6.70881 30 15 30C23.2905 30 30 23.2912 30 15C30 6.70951 23.2912 0 15 0ZM15.8789 28.2137V1.78635C22.691 2.22955 28.2422 7.88777 28.2422 15C28.2422 22.1103 22.6942 27.7702 15.8789 28.2137Z" fill="white" />
                              </svg>
                          </div>
                      </div>
                      <div className="menu">
                          <div className="mode neu" onClick={() => {menuToggler(); menuButtonClick()}}>
                              <svg 
                              height="20" 
                              viewBox="0 0 33 33" 
                              fill="none" 
                              xmlns="http://www.w3.org/2000/svg">
                                  <path d="M33 23.8333H0V27.5H33V23.8333Z" fill="black"/>
                                  <path d="M33 14.6667H0V18.3334H33V14.6667Z" fill="black"/>
                                  <path d="M33 5.5H0V9.16669H33V5.5Z" fill="black"/>
                              </svg>
                          </div>
                      </div>
                    </div>
                    
                </div>
            </nav>


            <div className="backdrop">
                <div className={menu ? 'menu-open': 'menu-closed'}>
                    <div className={menu ? 'menu-panel': 'menu-panel-none'}>
                        
                        <div className="menu-top ">
                            <div className="menu-top-link ">
                                
                                <div className="menu-top-in neu">
                                    <NavLink className="menu-nav-link" onClick={() => menuToggler()} exact to='/'>
                                        <div className="each-menu">
                                            <span>Home</span>
                                            <svg height="15" viewBox="0 0 42 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M41.1493 33.4018L5.73415 1.12786C4.572 -0.0283801 2.68725 -0.0283801 1.52511 1.12786C0.362968 2.28402 0.362968 4.16001 1.52511 5.31626L34.8049 35.6465L1.52806 65.9767C0.365916 67.1329 0.365916 69.0089 1.52806 70.1651C2.6902 71.3212 4.57495 71.3212 5.73701 70.1651L41.1522 37.8911C41.7716 37.2747 42.0372 36.4576 41.9958 35.6495C42.0343 34.8383 41.7689 34.0213 41.1493 33.4018Z" fill="black" />
                                            </svg>
                                        </div>
                                    </NavLink>

                                    <hr />

                                    <NavLink className="menu-nav-link" onClick={() => menuToggler()} to='/services'>
                                        <div className="each-menu">
                                            <span>Services</span>
                                            <svg height="15" viewBox="0 0 42 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M41.1493 33.4018L5.73415 1.12786C4.572 -0.0283801 2.68725 -0.0283801 1.52511 1.12786C0.362968 2.28402 0.362968 4.16001 1.52511 5.31626L34.8049 35.6465L1.52806 65.9767C0.365916 67.1329 0.365916 69.0089 1.52806 70.1651C2.6902 71.3212 4.57495 71.3212 5.73701 70.1651L41.1522 37.8911C41.7716 37.2747 42.0372 36.4576 41.9958 35.6495C42.0343 34.8383 41.7689 34.0213 41.1493 33.4018Z" fill="black" />
                                            </svg>
                                        </div>
                                    </NavLink>

                                    <hr />

                                    <NavLink className="menu-nav-link" onClick={() => menuToggler()} to='/about'>
                                    <div className="each-menu">
                                        <span>About</span>
                                        <svg height="15" viewBox="0 0 42 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M41.1493 33.4018L5.73415 1.12786C4.572 -0.0283801 2.68725 -0.0283801 1.52511 1.12786C0.362968 2.28402 0.362968 4.16001 1.52511 5.31626L34.8049 35.6465L1.52806 65.9767C0.365916 67.1329 0.365916 69.0089 1.52806 70.1651C2.6902 71.3212 4.57495 71.3212 5.73701 70.1651L41.1522 37.8911C41.7716 37.2747 42.0372 36.4576 41.9958 35.6495C42.0343 34.8383 41.7689 34.0213 41.1493 33.4018Z" fill="black" />
                                        </svg>
                                    </div>
                                    </NavLink>
                                </div>

                                <div className="menu-bot-in neu">
                                    <NavLink className="menu-nav-link" onClick={() => menuToggler()} exact to='/contact'>
                                        <div className="each-menu">
                                            <span>Contact</span>
                                            <svg height="15" viewBox="0 0 42 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M41.1493 33.4018L5.73415 1.12786C4.572 -0.0283801 2.68725 -0.0283801 1.52511 1.12786C0.362968 2.28402 0.362968 4.16001 1.52511 5.31626L34.8049 35.6465L1.52806 65.9767C0.365916 67.1329 0.365916 69.0089 1.52806 70.1651C2.6902 71.3212 4.57495 71.3212 5.73701 70.1651L41.1522 37.8911C41.7716 37.2747 42.0372 36.4576 41.9958 35.6495C42.0343 34.8383 41.7689 34.0213 41.1493 33.4018Z" fill="black" />
                                            </svg>
                                        </div>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className={menu ? 'closed': 'open'}>
                    <Switch>
                        {/* <Route path='/' exact component={Home} /> */}
                        <Route path='/' exact render={() => <Home theme={theme} />}/>
                        <Route path='/services' render={() => <Services theme={theme} />} />
                        <Route path='/about' render={() => <About theme={theme} />} />
                        <Route path='/contact' render={() => <Contact theme={theme} />} />
                        <Route path='/terms-and-conditions' render={() => <Terms theme={theme} />} />
                        <Route path='/privacy-policy' render={() => <Privacy theme={theme} />} />
                        <Route path='/pricing' render={() => <Pricing theme={theme} />} />

                        <Route path='/websites' render={() => <Projects theme={theme} />} />
                        <Route path='/ar' render={() => <AR theme={theme} />} />
                        
                        <Route path='*' render={() => <PageNotFound theme={theme} />} />
                    </Switch>
                </div>
                
                <footer className={menu ? 'closed': 'open'}>
                    <section className="footer section-footer">
                        <div className="wrapper">
                          <div className='footer-holder'>
                            <div className='footer-left'>
                              <div class="footer-grid">
                                <div className='copyright'>
                                  <div>
                                    {/* <img src={logoGray} ></img> */}
                                    <svg viewBox="0 0 1024 1024" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <g clip-path="url(#clip0_402_2)">
                                      <path d="M844 0H180C80.59 0 0 80.59 0 180V844C0 943.41 80.59 1024 180 1024H844C943.41 1024 1024 943.41 1024 844V180C1024 80.59 943.41 0 844 0ZM394 582.81C394 625.41 382 658.22 358.02 681.25C334.03 704.28 301.7 715.79 261.02 715.79C220.34 715.79 184.26 703.7 158.55 679.52C132.83 655.34 119.98 621 119.98 576.48H217.84C217.84 593.37 221.29 606.13 228.2 614.76C235.11 623.39 245.08 627.71 258.13 627.71C270.02 627.71 279.23 623.88 285.76 616.2C292.28 608.53 295.55 597.4 295.55 582.81V307.64H393.99V582.81H394ZM690.17 686.43C658.12 703.32 620.99 711.76 578.78 711.76H427.38V307.64H578.78C621.38 307.64 658.6 316.09 690.46 332.97C722.31 349.86 746.96 373.55 764.43 404.06C781.89 434.57 790.62 469.78 790.62 509.69C790.62 549.6 781.89 584.34 764.43 615.04C746.96 645.75 722.21 669.54 690.17 686.42V686.43ZM887.61 701.11C876.67 711.28 862.76 716.37 845.88 716.37C829 716.37 814.51 711.29 803.57 701.11C792.63 690.95 787.16 678.37 787.16 663.4C787.16 648.43 792.63 635.19 803.57 624.83C814.51 614.47 828.61 609.29 845.88 609.29C863.15 609.29 876.68 614.47 887.61 624.83C898.55 635.19 904.02 648.05 904.02 663.4C904.02 678.75 898.55 690.94 887.61 701.11Z" fill="white"/>
                                      <path d="M572.44 391.69H525.81V626.56H572.44C609.66 626.56 638.64 616.4 659.36 596.05C680.08 575.71 690.45 546.93 690.45 509.7C690.45 472.47 680.09 443.5 659.36 422.78C638.64 402.06 609.66 391.69 572.44 391.69Z" fill="white"/>
                                      </g>
                                      <defs>
                                      <clipPath id="clip0_402_2">
                                      <rect width="1024" height="1024" fill="white"/>
                                      </clipPath>
                                      </defs>
                                    </svg>
                                  </div>
                                  <div className='info'>
                                    <span>© 2024 Jins Gonzales Design</span>
                                    {/* <span>Site developed by 
                                      <a target="_blank" rel="noopener noreferrer" href="https://design.jinsgonzales.com"> Jins Gonzales Design</a>
                                    </span> */}
                                    <span>
                                      <NavLink className='nav' exact to='/terms-and-conditions'>
                                      <span className='terms'>Terms & Conditions</span>
                                      </NavLink>
                                      <span> • </span>
                                      <NavLink className='nav' exact to='/privacy-policy'>
                                      <span className='privacy'>Privacy Policy</span>
                                      </NavLink>
                                    </span>
                                  </div>
                                </div>
                                <div className='site-map'>
                                  <span className='footer-title'>Site Map</span>
                                  <div className='links-only'>
                                    <NavLink className='nav' exact to='/'>
                                      <span className='site-mapLink'>Home</span>
                                    </NavLink>
                                    <NavLink className='nav' exact to='/websites'>
                                      <span className='site-mapLink'>Websites</span>
                                    </NavLink>
                                    <NavLink className='nav' exact to='/ar'>
                                      <span className='site-mapLink'>Explore AR</span>
                                    </NavLink>
                                    <NavLink className='nav' exact to='/services'>
                                      <span className='site-mapLink'>Services</span>
                                    </NavLink>
                                    <NavLink className='nav' exact to='/about'>
                                      <span className='site-mapLink'>About</span>
                                    </NavLink>
                                    <NavLink className='nav' exact to='/contact'>
                                      <span className='site-mapLink'>Contact</span>
                                    </NavLink>
                                    {/* <span className='site-mapLink'>Services</span>
                                    <span className='site-mapLink'>About</span>
                                    <span className='site-mapLink'>Contact</span> */}
                                  </div>
                                  
                                </div>
                                <div className='soc-med'>
                                  <span className='footer-title'>Social Media</span>
                                  <div className='links-only'>
                                    <span className='soc-medLink'>
                                      <a target="_blank" rel="noopener noreferrer" href="https://facebook.com/jinsgonzales.design" onClick={() => facebookButtonClick()}>
                                        Facebook
                                      </a>
                                    </span>
                                    <span className='soc-medLink'>
                                      <a target="_blank" rel="noopener noreferrer" href="https://instagram.com/jinsgonzales.design/" onClick={() => instagramButtonClick()}>
                                        Instagram
                                      </a>
                                    </span>
                                  </div>
                                  
                                </div>
                              </div>
                            </div>
                            <div className='footer-right'>
                              {/* <span>Experience in AR</span>
                              <div className='qr'>
                                <img src={qrCode} ></img>
                              </div> */}
                            </div>
                          </div>
                          

                        </div>
                    </section>
                </footer>
            </div>
        </ScrollToTop>
        </Router>
        </ThemeProvider>
        
    )
}


export default Nav;
