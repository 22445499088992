import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom';

import styled, { ThemeProvider } from 'styled-components'
import { lightTheme, darkTheme, GlobalStyles } from '../themes.js'

function PageNotFound(themeData) {

  useEffect(() => {
    document.title = '404 Page Not Found • Jins Gonzales Design';
  }, []);

    const history = useHistory();
  
    return (
        <ThemeProvider theme={themeData.theme === 'light' ? lightTheme : darkTheme}>
        <GlobalStyles />
        <div id='pagenotfound'>

          <section className="pagenotfound section-top">
            <div className='pagetopMargin'></div>
            <div className="wrapper">
                <div className="flex flex-column">
                    <span className="title">404 Page not found :(</span>
                    {/* <span className='notfound'>Sorry, the page you were looking for does not exist.</span> */}
                    <span onClick={() => history.push('/')} className="subtitle">
                        <span>Home</span>
                        <svg height="15" viewBox="0 0 42 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M41.1493 33.4018L5.73415 1.12786C4.572 -0.0283801 2.68725 -0.0283801 1.52511 1.12786C0.362968 2.28402 0.362968 4.16001 1.52511 5.31626L34.8049 35.6465L1.52806 65.9767C0.365916 67.1329 0.365916 69.0089 1.52806 70.1651C2.6902 71.3212 4.57495 71.3212 5.73701 70.1651L41.1522 37.8911C41.7716 37.2747 42.0372 36.4576 41.9958 35.6495C42.0343 34.8383 41.7689 34.0213 41.1493 33.4018Z" fill="black" />
                        </svg>
                    </span>
                    {/* <span onClick={() => history.push('/projects')} className="subtitle">
                        <span>Projects</span>
                        <svg height="15" viewBox="0 0 42 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M41.1493 33.4018L5.73415 1.12786C4.572 -0.0283801 2.68725 -0.0283801 1.52511 1.12786C0.362968 2.28402 0.362968 4.16001 1.52511 5.31626L34.8049 35.6465L1.52806 65.9767C0.365916 67.1329 0.365916 69.0089 1.52806 70.1651C2.6902 71.3212 4.57495 71.3212 5.73701 70.1651L41.1522 37.8911C41.7716 37.2747 42.0372 36.4576 41.9958 35.6495C42.0343 34.8383 41.7689 34.0213 41.1493 33.4018Z" fill="black" />
                        </svg>
                    </span> */}
                </div>
            </div>
          </section>

        </div>
        </ThemeProvider>
        
    )
}

export default PageNotFound;